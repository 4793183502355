import {BusinessModel} from './business';

export enum EContactType {
  General = 1,
  Legal = 2,
  Invoice = 3,
  OCSendTo = 11,
  InvoiceSendTo = 8,
  DNSendTo = 9,
  TCSendTo = 10,
  Delivery = 4,
  PickUp = 6,
  Employee = 5,
  ExEmployee = 7,
}

export enum EContactFieldType {
  JobTitle = 2,
  Street = 3,
  Zip = 4,
  City = 6,
  State = 5,
  Country = 7,
  Info = 25,
  RegistrationNumber = 8,
  VAT_Number = 9,
  Phone = 10,
  Mobile = 11,
  BusinessLandline = 12,
  Fax = 13,
  Email = 14,
  Bank = 15,
  IBAN = 16,
  BIC = 17,
  // ShortCode = 18,
  // DeliveryCondition = 19,
  ReceptionHours = 26,
  PaymentText = 24,
  PaymentConditions = 20,
  Incoterms = 21,
  IncotermsPlace = 22,
  IncotermsCountry = 23,
  Instructions = 27,
  SendingDate = 29,
  SendingDays = 30,
  SendMode = 28,
}

export class ContactFieldModel {
  public type: EContactFieldType;
  public value: string;
  public comment?: string;
}

export class ContactModel {
  public _id?: string;
  public type: EContactType;
  public title: string;
  // public attn?: ContactModel;
  public fields: ContactFieldModel[];
  public business?: BusinessModel;
  public displayTitle?: string;
  public addressName?: string;
}
