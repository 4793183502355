import { UserModel } from "./user";

export enum EDebugType {
    any = 1,
    log = 2,
    trace = 3,
    docTrace = 4,
    mongoError = 5,
    backend = 6,
    frontend = 7,
    console = 8,
    docChange = 9,
}

export class DebugModel {
    id: number;
    type: EDebugType;
    date: Date;
    user: UserModel;
    username: string;
    subject: string;
    version: string;
    data: any;
}