<div class="header-container"
     [class.left]="position === 'normal'"
     [class.right]="position === 'inverse'">
  <div class="logo-containter">
    <a (click)="toggleSidebar()" href="#" class="navigation"><i class="nb-menu"></i></a>
    <!-- <div class="logo" (click)="goToHome()">Nipponrika</div> -->
    <div class="logo" (click)="goToHome()"><img style="height: 2rem;" src="assets/images/nipponrika_white_32.png"></div>
  </div>
  <!--<ngx-theme-switcher></ngx-theme-switcher>-->
</div>

<div *ngIf="developement" class="debug-text">TEST ENVIRONMENT</div>

<nb-actions
  size="medium"
  class="header-container"
  [class.right]="position === 'normal'"
  [class.left]="position === 'inverse'">

  <nb-action>
    <!-- <nb-user [name]="user?.username" [picture]="user?.picture"></nb-user> -->
    <div class="user-logo">{{auth.user?.short}}</div>
  </nb-action>
  <nb-action *ngIf="headerComponent.unreadMessages.length">
    <nb-icon style="color:white; cursor: pointer" icon="email-outline" (click)="popMessage()"></nb-icon>
    <div class="badge">{{headerComponent.unreadMessages.length}}</div>
  </nb-action>
  <nb-action *ngIf="!tabCoordiantorService.isMasterTab">
    <button class="btn btn-primary btn-sm" (click)="tabCoordiantorService.makeMaster()">Edit</button>
  </nb-action>
  <nb-action class="expiry-time">
    <div>Autologout: {{expiryTime}}</div>
  </nb-action>
  <!--<nb-action icon="nb-grid-b" class="toggle-layout" (click)="toggleSettings()"></nb-action>

  <nb-action class="control-item" disabled icon="nb-notifications"></nb-action>
  <nb-action class="control-item" icon="nb-email"></nb-action>
  <nb-action class="control-item">
    <nb-search type="rotate-layout" (click)="startSearch()"></nb-search>
  </nb-action>-->
</nb-actions>
