import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { ApiService } from './api.service';


class Progress {
  private openProgressSubject = new Subject<string>();
  openProgress = this.openProgressSubject.asObservable();

  private showProgressSubject = new Subject<{id: string, text: string, value: number}>();
  showProgress = this.showProgressSubject.asObservable();

  private closeProgressSubject = new Subject<string>();
  closeProgress = this.closeProgressSubject.asObservable();

  private addProgressMessageSubject = new Subject<{id: string, message: string}>();
  addProgressMessage = this.addProgressMessageSubject.asObservable();

  private onOpenProgress(id: string) {
    this.openProgressSubject.next(id);
  }

  private onShowProgress(id: string, text: string, value: number) {
    this.showProgressSubject.next({ id: id, text: text, value: value });
  }

  private onCloseProgress(id: string) {
    this.closeProgressSubject.next(id);
  }

  private onAddMessage(id: string, message: string) {
    this.addProgressMessageSubject.next({ id: id, message: message });
  }

  public eval(pkg: any) {
    const env = JSON.parse(pkg);
    const data = env && env.data;
    if (env && env.command === 'open') this.onOpenProgress(env.id)
    else if (env && env.command === 'close') this.onCloseProgress(env.id)
    else if (data) this.onShowProgress(data.id, data.text, +data.value )
    else if (env && env.message) this.onAddMessage(env.id, env.message);
    else if (env && env.error) {
      setTimeout( () => {
        this.onShowProgress(env.id, env.error, 0);
      }, +env.time)
    }
  }

}

@Injectable({
  providedIn: 'root',
})
export class SocketService implements OnDestroy {

  retrySeconds = 10;
  wsUrl = 'ws://localhost:3001';

  connection: WebSocketSubject<any>;

  watchdog: any = null

  progress = new Progress();

  constructor(private api: ApiService) {
    this.connect();
  }

  ngOnDestroy(): void {
    this.connection.complete(); // Closes the connection.
  }

  connect(): Observable<any> {
    this.connection = webSocket(`${this.wsUrl}?token=${this.api.token}`);
    this.connection.subscribe(
      msg => this.handler(msg),
      err => this.handleClose(err),
      () => this.handleClose(),
    );
    return this.connection;
  }

  handler(packet: any) {
    if (packet.id === 'progress') this.progress.eval(packet.data);
  }

  handleClose(err = null) {
    console.warn('socket lost', err);

    // setTimeout( () => {
    //   console.log('reconnecting socket');
    //   this.connect();
    // }, 10000);
  }

}
