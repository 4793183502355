import {VersionModel} from './version';
import {ContactModel} from './contact';
import {ProjectModel} from './project';
import {BusinessUnitModel} from './businessUnit';
import {RegionModel} from './region';
import {ActivityModel} from './activity';

export enum EBusinessType {
  Customer = 1,
  Supplier,
  // Employee,
  NRK,
  Template,
}

export class BusinessField {
  id: string;
  checked: boolean = true;
}

export class BusinessModel extends VersionModel {
  public _id: string;
  public active: boolean;
  public name: string;
  public type: EBusinessType;
  public remark: string;


  public contacts: ContactModel[];
  public projects: ProjectModel[];
  public businessUnits: BusinessUnitModel[];
  public region: RegionModel | string;

  public manager?: string | ContactModel;


  public activities?: ActivityModel[];
  // public updatedAt: number;
  // public createdAt: number;
}
