import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getDocumentNumber, mongoId } from '../../../../@core/common/global';
import { ApiService } from '../../../../@core/service/api.service';
import { SharedDataService } from '../../../../@core/service/shared-data.service';
import { FileModel, UserModel } from '../../../../@models';
import { CompilationModel } from '../../../../@models/erp/compilation';
import { CompilationTypeData, InvoiceStatusData, SpoStatusData,
  TransportStatusData, DocConfig } from '../../../../@models/erp/compilationData';
import { AbstractItemType } from '../../abstractItem';

export enum ChangelogEntryType {
  draft = 1,
  init,
  edit,
  newVersion,
  revised,
  cancelled,
  autolog,
  clone,
}

export class ChangelogEntry {
  version: number;
  date: Date;
  user: UserModel;
  type: ChangelogEntryType;
  documentNumber?: string;
  previousDoc?: { code: string, version: number, editCount: number };
  change: string;
  items: { quickText: string, text: string }[] = [];
  showInPdf = true;
  dirty = false;
  files: FileModel[];

  constructor(doc: AbstractItemType = null, user: UserModel = null, change: string = null ) {
    this.version = doc && doc.version;
    this.date = new Date();
    this.type = ChangelogEntryType.autolog;
    this.user = mongoId(user);
    this.change = change || 'Edit ' + getDocumentNumber(doc);
    this.documentNumber = getDocumentNumber(doc);
    this.items = [];
    this.showInPdf = true;
  }
}

export class Changelog {

  static typeToString(type: ChangelogEntryType) {
    switch (type) {
      case 1: return 'draft';
      case 2: return 'init';
      case 3: return 'edit';
      case 4: return 'new version';
      case 5: return 'revised';
      case 6: return 'cancelled';
      case 7: return 'autolog';
      case 8: return 'clone';
    }
  }
  // let entry = doc.changelog[0] as ChangelogEntry;
  // if (entry.type === ChangelogEntryType.autolog
  //   && entry.documentNumber === getDocumentNumber(doc)
  //   && new Date(entry.date).toDateString() === new Date().toDateString()) {

  //   entry.items.push(...changes);
  //   console.log('push cl');
  // } else {
  //   entry = new ChangelogEntry(doc, this.auth.user);
  //   entry.items = changes;
  //   (doc.changelog as ChangelogEntry[]).unshift(entry);
  // }

  // eslint-disable-next-line max-len
  static async addAutolog(doc: AbstractItemType, user: UserModel, api: ApiService, changes: {quickText: string, text: string}[]) {
    await this.convertLegacyChangelog(doc, api); // TODO: remove legacy code
    let entry = doc.changelog[0] as ChangelogEntry;
    if ((
      entry.type === ChangelogEntryType.autolog
      && entry.documentNumber === getDocumentNumber(doc)
      && new Date(entry.date).toDateString() === new Date().toDateString()
    ) || (
      entry.type === ChangelogEntryType.edit
      && entry.documentNumber === getDocumentNumber(doc)
      && new Date(entry.date).toDateString() === new Date().toDateString()
      && !entry.items.find(f => (f.quickText && f.quickText.length) > 0 || (f.text && f.text.length) > 0 )
    )) {
      entry.type = ChangelogEntryType.autolog
      entry.items = entry.items.filter(f => (f.quickText && f.quickText.length) > 0 || (f.text && f.text.length) > 0 );
      entry.items.unshift(...changes);
    } else {
      entry = new ChangelogEntry(doc, user);
      entry.items = changes;
      (doc.changelog as ChangelogEntry[]).unshift(entry);
    }
  }

  // eslint-disable-next-line max-len
  static async addAndSaveAutolog(doc: CompilationModel, user: UserModel, api: ApiService, changes: {quickText: string, text: string}[]) {
    await Changelog.addAutolog(doc, user, api, changes);
    // api.saveCompilation(doc as CompilationModel); // TODO: abstraction
    api.updateField(doc.code, 'changelog', doc.changelog);
  }

  static async saveChanges(orig: CompilationModel, doc: CompilationModel, fieldName: string, api: ApiService, user: UserModel) {
    const changes = Changelog.changeMap(orig, doc, fieldName);
    await Changelog.addAndSaveAutolog(doc, user, api, changes );
  }

  static sameChangelogBaseEntry(a: ChangelogEntry, b: ChangelogEntry = null,
    user: UserModel = null, change: string = null, documentNumber = null): boolean {

    if (!a) return false;

    if (!b) {
      b = new ChangelogEntry();
      b.date = new Date();
      b.user = user;
      b.documentNumber = documentNumber;
      b.change = change;
    }

    return new Date(a.date).toDateString() === new Date(b.date).toDateString()
      && mongoId(a.user) === mongoId(b.user)
      && a.documentNumber === b.documentNumber
      && a.change === b.change
  }

  static changeMap(orig: AbstractItemType, doc: AbstractItemType, fieldName: string = null ) {

    const changes = [];

    const getText = (name = fieldName, origField: any = orig, docField: any = doc) => {
      let from = origField[name] || 'undefined';
      let to = docField[name] || 'undefined';

      if (
        docField[name] instanceof Date || /^\d\d\d\d-\d\d-\d\d/.test(docField[name])
        || origField[name] instanceof Date || /^\d\d\d\d-\d\d-\d\d/.test(origField[name])
      ) {
        // eslint-disable-next-line max-len
        from = origField[name] && (origField[name].toISOString && origField[name].toISOString() || origField[name]).replace(/T.*/, '');
        // eslint-disable-next-line max-len
        to = docField[name] && (docField[name].toISOString && docField[name].toISOString() || docField[name]).replace(/T.*/, '');
      }

      const textFromDataClass = (cls: any) => {
        const of = isNaN(origField[name]) ? origField[name] : +origField[name];
        const df = isNaN(docField[name]) ? docField[name] : +docField[name];
        from = (cls.get(of) || {} as any).name;
        to = (cls.get(df) || {} as any).name;
      }

      switch (name) {
        case 'transportStatus': textFromDataClass(TransportStatusData); break;
        case 'spoStatus': textFromDataClass(SpoStatusData); break;
        case 'invoiceStatus': textFromDataClass(InvoiceStatusData); break;
        default: switch (typeof docField[name]) {
          case 'boolean': return docField[name] === true ? 'From no to yes' : 'From yes to no';
        }
      }
      return `From ${from || 'undefined'} to ${to || 'undefined'}`;
    }

    const addChange = (name = fieldName, origField: any = orig, docField: any = doc, qtext: string = null) => {
      if (!docField
        || (!origField && !docField)
        || (!origField[name] && !docField[name])
        || (!origField[name] && doc.draft))
        return;

      if (origField[name] !== docField[name]) {
        if (!qtext) qtext = 'Change ' + (DocConfig.get(name) || {}).label;
        const anotherDoc = orig.code !== doc.code ? ` in ${getDocumentNumber(doc)}` : '';
        changes.unshift({ quickText: qtext, text: getText(name, origField, docField) + anotherDoc });
      }
    }

    // const addPfspoChanges = () => {
    //   const origPfspos = ((orig as CompilationModel).lineItems || []).map(li => (li.pfspo || []).map(spo => spo.code));
    //   const docPfspos = ((doc as CompilationModel).lineItems || []).map(li => (li.pfspo || []).map(spo => spo.code));
    //   docPfspos.forEach((spos, idx) => {
    //     spos.forEach(spo => {
    //       if (!(origPfspos[idx] || []).includes(spo)) {
    //         changes.unshift({ quickText: 'Assign PFSPO', text: `Add: Pos ${idx+1}: ${spo}` });
    //       }
    //     })
    //   });
    //   origPfspos.forEach((spos, idx) => {
    //     spos.forEach(spo => {
    //       if (!(docPfspos[idx] || []).includes(spo)) {
    //         changes.unshift({ quickText: 'Assign PFSPO', text: `Remove: Pos ${idx+1}: ${spo}` });
    //       }>
    //     })
    //   });
    // }

    // *** change map main ***

    if (fieldName) switch (fieldName) {
      case 'completelyInvoiced':
        changes.unshift({ quickText: 'Change completely invoiced', text: getText() });
        break;
      case 'invoiceStatus':
        addChange('invoiceStatus');
        break
    } else {
      if (doc && (doc as CompilationModel).documentStatus) { // TODO: instanceof -> no object copies
        doc = doc as CompilationModel || {} as CompilationModel;
        orig = orig as CompilationModel || {} as CompilationModel;
        // switch (doc.type) {
        //   case 3: /* order confirmation */
        //     addChange('vieDispatchDate');
        //     addChange('customerAcceptedDeliveryDate');
        //     addChange('customerRequestedDeliveryDate');
        //     // addPfspoChanges(); // OC -> line items -> pfspo changes
        //     break;
        //   case 4: /* supplier purchase order*/
        //   case 6: /* proforma supplier purchase order */
        //   case 16: /* combined shipment */
        //     addChange('exwJapanDate');
        //     addChange('arrivingVieDate');
        //     addChange('transportStatus', orig.transportRun, doc.transportRun );
        //     addChange('spoStatus', orig.transportRun, doc.transportRun );
        //     break;
        // }

        addChange('exwJapanDate');
        addChange('transferTime1');
        addChange('arrivingVieDate');
        addChange('vieDispatchDate');
        addChange('expectedNextInvoiceDate');
        addChange('transferTime2');
        addChange('customerRequestedDeliveryDate');
        addChange('customerAcceptedDeliveryDate');
        addChange('actualDeliveryDate');
        addChange('transportStatus', orig.transportRun, doc.transportRun );
        addChange('spoStatus', orig.transportRun, doc.transportRun );

      }
    }

    return changes;
  }

  static async convertLegacyChangelog(item: AbstractItemType, api: ApiService) {
    if (typeof item.changelog === 'string' || !item.changelog) {
      if ((item as any).documentStatus) { // CompilationModel
        const items = await api.loadCompilationsByCode(item.code);
        items.forEach((doc, idx) => {
          doc['prevDocumentNumber'] = getDocumentNumber(idx < items.length-1 && items[idx+1] as any)
        })
        const entries = items.map(v => ({
          version: v.version,
          date: (v as any).updatedAt,
          user: v.owner || v.issuedBy,
          type: v.version === 1 ? ChangelogEntryType.init : ChangelogEntryType.newVersion,
          documentNumber: getDocumentNumber(v),
          change: v.version === 1 ? 'INI' : `${v['prevDocumentNumber']} to ${getDocumentNumber(v as any)}`,
          items: [{ quickText: '', text: v.changelog }],
          showInPdf: true,
        })) as ChangelogEntry[];
        item.changelog = entries;
      }
      // // TODO: convert all changelog strings to ChangelogEntry in database & remove legacy code
      // const versions = this.items.filter(f => f.code === item.code).sort((a, b) => a.version < b.version ? 1 : -1);
      // const items = [] as any;
      // for (let idx = 0; idx < versions.length; idx++) {
      //   const v = versions[idx];showFileWidgetshed product or base product
      // //   const entries = items.map(v => ({
      // //     version: v.version,
      // //     date: v.updatedAt,
      // //     user: v.owner || v.issuedBy,
      // //     type: v.version === 1 ? ChangelogEntryType.init : ChangelogEntryType.newVersion,
      // //     text: v.changelog,
      // //     showInPdf: true,
      // //   })) as ChangelogEntry[];
      // //   this.changelog = item.changelog as string;
      // //   item.changelog = entries;
      // // } else {  // compilation
      // }
    }
  }
}

@Component({
  selector: 'ngx-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss'],
})
export class ChangelogComponent implements OnInit {

  @Input() compilation: CompilationModel;
  @Input() abstractItem: any; // BaseProductModel | FinishedProductModel | CompilationModel
  @Input() print = false;
  @Input() disabled = false;
  @Input() lite = false;

  @Output() uploadCompleted = new EventEmitter()

  quickTextOptions: { id: string, name: string }[] = [];

  showFileWidget: boolean[] = [];

  constructor(public api: ApiService, private data: SharedDataService) { }

  async ngOnInit() {
    const quickTextData = await this.api.getCompilationData('changelog') as any; // TODO: type: CompilationDataModel[]
    let itemQt: any;
    if (this.abstractItem.documentStatus) { // CompilationModel
      itemQt = quickTextData.find(f => f.id === CompilationTypeData.get(this.abstractItem.type).code) || { data: [] };
      const generalQt = quickTextData.find(f => f.id === 'GN');
      this.quickTextOptions = [
        ...generalQt.data.map(m => ({ id: m, name: m})),
        ...itemQt.data.map(m => ({ id: m, name: m})),
      ];
    } else if (this.abstractItem.product) { // FinishedProductModel
      itemQt = quickTextData.find(f => f.id === 'FP');
      this.quickTextOptions = itemQt.data.map(m => ({ id: m, name: m}));
    } else { // BaseProductModel
      itemQt = quickTextData.find(f => f.id === 'BP');
      this.quickTextOptions = itemQt.data.map(m => ({ id: m, name: m}));
    }
    this.data.loadUsers();
  }

  getUserShort(userId: string) {
    const user = this.data.users?.find(u => u._id === mongoId(userId));
    return user ? user.short : '';
  }

  getChangeType(changeType: number) {
    return Changelog.typeToString(changeType);
  }

  addLogItem(logEntry: any) {
    logEntry.items.unshift({ quickText: '', text: '' });
  }

  removeLogItem(logEntry: any, idx: number) {
    logEntry.items.splice(idx, 1);
  }

  fileChecked(file: FileModel) {
    file.checked = !file.checked;
    if (this.abstractItem['recipient']) { // is CompilationModel => auto save
      this.api.updateField(this.abstractItem.code, 'changelog', this.abstractItem.changelog);
    }
  }

}
