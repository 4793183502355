import { Pipe, PipeTransform } from '@angular/core';
import {CommonModule} from '@angular/common';

@Pipe({
  name: 'enumKeys',
})
export class EnumKeysPipe implements PipeTransform {

  transform(value: any): any {
    // const map: {key: string, value: string}[] = [];
    const keys: {key: string, value: string}[] = [];
    // for (const enumMember in value) {
    //   if (!isNaN(parseInt(enumMember, 10))) {
    //     console.log('enumMember', enumMember, value[enumMember]);
    //     map.push({
    //       key: enumMember,
    //       value: value[enumMember],
    //     });
    //     // Uncomment if you want log
    //     // console.log("enum member: ", value[enumMember]);
    //   }
    // }
    for (const enumMember in value) {
      if (typeof value[enumMember] === 'number') {
        keys.push({ key: value[enumMember], value: enumMember }); // preserve sort order of Enum
      }
    }
    return keys;
  }

}
