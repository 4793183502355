import { Pipe, PipeTransform } from '@angular/core';
import { getDocumentNumber } from '../../@core/common/global';
import { CompilationModel } from '../../@models/erp/compilation';

@Pipe({ name: 'documentNumber' })
export class DocumentNumberPipe implements PipeTransform {

  transform(input: CompilationModel, fromArray: CompilationModel[] = null, withEdit = true, split = false): string {
    return getDocumentNumber(input, fromArray, withEdit, split);
  }
}
