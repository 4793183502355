import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { UserModel } from '../../@models';
import { ApiService } from './api.service';
import { waitForVariable } from '../common/global';

@Injectable()
export class AuthGuardService implements CanActivate {

  private userToken: {}
  private loadingUser = false;
  private nrkUser: UserModel;

  constructor(private api: ApiService, private authService: NbAuthService, private router: Router) {
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.userToken = token.getPayload();
          this.getMe();
        }
      });
  }

  get user() {
    return this.nrkUser;
  }

  logOut() {
    this.nrkUser = null;
    this.userToken = {};
  }

  async getMe() {
    return new Promise<UserModel>(res => {
      this.loadingUser = true;
      const scr = this.api.loadMe(true).subscribe(usr => {
        this.nrkUser = usr;
        this.loadingUser = false;
        res(usr)
        scr.unsubscribe();
      })
    })
  }
  loadMe = async () => {
    if (this.loadingUser) {
      await waitForVariable(this, 'nrkUser');
    }
    this.loadingUser = false;
    return this.nrkUser || await this.getMe();
  }

  canActivate() {
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated)
            this.router.navigate(['auth/login'])
        }));
  }
}
