import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CompilationModel } from '../../@models/erp/compilation';
import { NumberValueAccessor } from '@angular/forms';
import { WarehousingModel } from '../../@models/warehousing';

export interface IEventData {
  name: string;
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private eventSubject = new Subject<IEventData>();
  private reloadBoxesSubject = new Subject<any>();
  private preSaveCompilationSubject = new Subject<CompilationModel>();
  private postSaveCompilationSubject = new Subject<CompilationModel>();
  private recalcPackingSubject = new Subject<CompilationModel>();
  private issuerChangeSubject = new Subject<number>();
  // private commitBoxesSubject = new Subject<{ compilation: CompilationModel, idx: number }>();
  private showMessageSubject = new Subject<WarehousingModel>();

  emit(eventData: IEventData) {
    this.eventSubject.next(eventData);
  }

  get receiver() {
    return this.eventSubject.asObservable();
  }

  emitReloadBoxes() {
    this.reloadBoxesSubject.next(null);
  }

  get reloadBoxes() {
    return this.reloadBoxesSubject.asObservable();
  }

  emitPreSaveCompilation(compilation: CompilationModel) {
    this.preSaveCompilationSubject.next(compilation);
  }

  get preSaveCompilation() {
    return this.preSaveCompilationSubject.asObservable();
  }

  emitPostSaveCompilation(compilation: CompilationModel) {
    this.postSaveCompilationSubject.next(compilation);
  }

  get postSaveCompilation() {
    return this.postSaveCompilationSubject.asObservable();
  }

  emitRecalcPacking(compilation: CompilationModel) {
    this.recalcPackingSubject.next(compilation);
  }

  get recalcPacking() {
    return this.recalcPackingSubject.asObservable();
  }

  emitIssuerChange(issuer: number) {
    this.issuerChangeSubject.next(issuer);
  }

  get issuerChange() {
    return this.issuerChangeSubject.asObservable();
  }

  emitShowMessage(msg: WarehousingModel) {
    this.showMessageSubject.next(msg);
  }

  get showMessage() {
    return this.showMessageSubject.asObservable();
  }

  // emitCommitBoxes(data: { compilation: CompilationModel, idx: number }) {
  //   this.commitBoxesSubject.next({ compilation: data.compilation, idx: data.idx});
  // }

  // get commitBoxes() {
  //   return this.commitBoxesSubject.asObservable();
  // }

}