import {VersionModel} from './version';
import {BusinessModel} from './business';
import {UserModel} from './user';
import {ProjectModel} from './project';
import {ContactModel} from './contact';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class TopicActionModel {
  public contact: ContactModel;
  public name: string;
  public action: string;
  public date: string;
  public completed?: boolean;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


export class TopicModel {
  public businessUnit: string;
  public project: string;
  public topic: string;
  public action: string;
  public sort: number;
  public plan: TopicActionModel[];
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export enum EFileType {
  Unknown,
  Pdf,
  Image,
  Doc,
  Excel,
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class FileModel {
  public name: string;
  public type: EFileType;
  public url: string;
  public comment?: string;
  public additionalField?: string;
  public additionalDate?: Date; // dateOfRelease
  public checked?: boolean;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export enum EActivityContentType {
  QuickNote = 0,
  VisitReport = 1,
  Offer = 2,
  Order = 3,
  Invoice = 4,
  Note = 5,
  OrderConfirmation = 6,
  OnlineMeeting = 7,
  TradeShowMeeting = 8,
  OnSiteMeeting = 9
}

export enum EActivityOfferStatus {
  Initiation = 1,
  OnHold = 2,
  PO = 3,
  Finished = 6,
  Cancelled = 4,
  Lost = 5,
  Revised = 7,
}

export abstract class ActivityContentModel {
  public readonly type: EActivityContentType;
  public readonly short: string;

  public static getShort(type: EActivityContentType) {
    // switch (type) {
    //   case EActivityContentType.VisitReport: return 'VR';
    //   case EActivityContentType.Note: return 'NT';
    //   case EActivityContentType.QuickNote: return 'QN';
    //   case EActivityContentType.Offer: return 'OF';
    //   case EActivityContentType.Order: return 'OR';
    //   case EActivityContentType.OrderConfirmation: return 'OC';
    //   case EActivityContentType.Invoice: return 'IN';
    // }
    return ['QN', 'VR', 'OF', 'OR', 'IN', 'NT', 'OC', 'OM', 'TSM', 'OSM'][type];
  }

  protected constructor(type: EActivityContentType) {
    this.type = type;
  }
}

export enum EActivityStatus {
  default = 0,
  planned = 1,
  cancelled = 2,
  deleted = 3,
}
export class ActivityStatusData {
  private static opts = [
    { id: EActivityStatus.planned, name: 'Planned' },
    { id: EActivityStatus.default, name: 'Completed' },
    { id: EActivityStatus.cancelled, name: 'Cancelled', color: '#ddd', opacity: 0.5 },
    { id: EActivityStatus.deleted, name: 'Deleted', opacity: 0.5 },
  ];

  public static getOptions = () => ActivityStatusData.opts.map(m => ({ id: +m.id, name: m.name }));
  public static get = (id): { id: number, name: string, color?: string, opacity?: number }  => ActivityStatusData.opts.find(f => +f.id === +id) || ActivityStatusData.get(0);
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class VisitReportContentModel extends ActivityContentModel {
  public topics: TopicModel[];

  constructor() {
    super(EActivityContentType.VisitReport);
  }
}

export class NoteContentModel extends ActivityContentModel {
  public topics: TopicModel[];

  constructor() {
    super(EActivityContentType.Note);
  }
}

export class OnlineMeetingContentModel extends ActivityContentModel {
  public topics: TopicModel[];

  constructor() {
    super(EActivityContentType.OnlineMeeting);
  }
}

export class TradeShowMeetingContentModel extends ActivityContentModel {
  public topics: TopicModel[];

  constructor() {
    super(EActivityContentType.TradeShowMeeting);
  }
}

export class OnSiteMeetingContentModel extends ActivityContentModel {
  public topics: TopicModel[];

  constructor() {
    super(EActivityContentType.OnSiteMeeting);
  }
}

export class OfferContentModel extends ActivityContentModel {
  public text: string;

  constructor() {
    super(EActivityContentType.Offer);
  }
}

export class EmptyActivityContentModel extends ActivityContentModel {
  constructor() {
    super(EActivityContentType.QuickNote);
  }
}


export class OrderActivityContentModel extends ActivityContentModel {
  constructor() {
    super(EActivityContentType.Order);
  }
}


export class OrderConfirmationActivityContentModel extends ActivityContentModel {
  constructor() {
    super(EActivityContentType.OrderConfirmation);
  }
}

export class InvoiceActivityContentModel extends ActivityContentModel {
  constructor() {
    super(EActivityContentType.Invoice);
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


export enum EParticipantType {
  Reference,
  External,
}

export interface ParticipantModel {
  type: EParticipantType;
  name?: string;
  contact?: string;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class OfferListModel {

}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class ActivityModel extends VersionModel {
  public _id: string;
  public title: string;
  public business: BusinessModel;
  public location: string;
  public owner: UserModel;
  public date: string;
  public dateTo: string;

  public participants: ParticipantModel[];
  public purpose: string;

  public files: FileModel[];
  public pdfs: FileModel[];

  public content: ActivityContentModel;

  public project?: string | ProjectModel;

  public draft: boolean;
  public public: boolean;
  public hide: boolean;

  public seq?: number;
  public version?: number;
  public comment?: string;

  public versions?: ActivityModel[];
  public original: boolean;
  public planned: boolean;
  
  public status: EActivityStatus;
  public cancelReason: string;
}
