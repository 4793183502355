import { Component, Inject, OnInit } from '@angular/core';
import { NB_AUTH_OPTIONS, NbAuthService, NbLogoutComponent } from '@nebular/auth';
import { Router } from '@angular/router';
import { AuthGuardService } from '../@core/service/auth-guard.service';

@Component({
  template: '',
})
export class NbLogoutPatchComponent extends NbLogoutComponent implements OnInit {

  constructor(service: NbAuthService, @Inject(NB_AUTH_OPTIONS) options: any, router: Router, private auth: AuthGuardService) {
    super(service, options, router);
  }

  ngOnInit(){
    localStorage.removeItem('auth_app_token');
    this.auth.logOut();
    this.router.navigateByUrl('/auth/login');
  }

}
