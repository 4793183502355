import {ProjectSummaryModel} from './projectSummary';
import {BusinessUnitModel} from './businessUnit';
import {BusinessModel} from './business';
import {ContactModel} from './contact';

/* export class SubprojectModel {
  public _id: string;
  public name: string;
  public description?: string;
}*/


export enum EProjectType {
  NonComercial = 1,
  OneTimePO = 2,
  RepetitivePO = 3,
}

export enum EProjectStatus {
  Initiation = 1,
  PO = 2,
  Cancelled = 3,
  Finished = 4,
  OnHold = 5,
  Lost = 6,
}

export enum EProjectQuantityUnit {
  Disabled = 0,
  M2 = 1,
  Pc = 2,
}

export const EProjectQuantityUnitDisplay = [
  { id: EProjectQuantityUnit.Disabled, name: 'Manual'},
  { id: EProjectQuantityUnit.M2, name: 'm2'},
  { id: EProjectQuantityUnit.Pc, name: 'pc'},
];

export enum EProjectDevelopment {
  existing = 0,
  minor = 1,
  major = 2,
  new = 3,
  unknown = 4,
}

export const EProjectDevelopmentDisplay = [
  { id: EProjectDevelopment.existing, name: 'Existing product'},
  { id: EProjectDevelopment.minor, name: 'Existing product with small modification'},
  { id: EProjectDevelopment.major, name: 'Existing product with big modification'},
  { id: EProjectDevelopment.new, name: 'New Product'},
  { id: EProjectDevelopment.unknown, name: 'Not known'},
];

export class ProjectListModel {
  public _id: string;
  public businessUnit: string;
  public city: string;
  public contact: ContactModel;
  public country: string;
  public customer: string;
  public description: string;
  public equipmentValue: number | string;
  public information: string;
  public kickoff: string;
  public name: string;
  public offerDate: string;
  public orderDate: string;
  public potential: number;
  public probability: number | string;
  public share: number | string;
  public material: string;
  public sales: string;
  public lastActivity: string;
  public topic?: boolean;
  public development?: number | string;
  public unit?: number | string;
  public nipponrikaPotential: number | string;
  public nipponrikaValue: number | string;
  public business: string;
  public title?: string; // for connected projects only
  public connectedProjects: ProjectModel[] | string;
  connectedValue: string;

}

export interface IMilestoneModel {
  name: string;
  date?: string;
  completion: number;
  text?: string;
}

export class ProjectModel {
  public _id?: string;
  public name: string;
  public description?: string;
  public businessUnit: BusinessUnitModel;
  // public subprojects: SubprojectModel[];

  public development?: EProjectDevelopment;
  public type?: EProjectType;
  public potential?: number;
  public currency?: string;
  public probability?: number;
  public share?: number;
  public kickoff?: string;
  public firstPo?: string;
  public status?: EProjectStatus;
  public material?: string;
  public baseProduct?: string; // base product code
  public offer?: string; // offer code
  public offerDate?: string;
  public orderDate?: string;
  public showInReport?: boolean;
  public unit?: EProjectQuantityUnit;
  public quantity?: number;
  public price?: number;

  public summary?: ProjectSummaryModel[];

  public business?: BusinessModel;
  public milestones?: IMilestoneModel[];
  public projects?: ProjectModel[];
}
