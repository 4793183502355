import { CompilationModel } from '../../@models/erp/compilation';
import { CompilationTypeData, DocConfig } from '../../@models/erp/compilationData';
import { AbstractItemType } from '../../pages/erp/abstractItem';
const moment = require('moment');

export const mongoId = (model) => (model && model._id ? model._id : model);

// export const isEmpty = (obj: any) => obj === null || obj === undefined || (typeof obj === 'array' && obj.length === 0) || (obj && !Object.keys(obj).length);
export const isEmpty = (obj: any, extended = false) => 
  extended 
  ? obj === null || obj === undefined || (typeof obj === 'string' && obj.trim() === '') || (typeof obj === 'number' && obj === 0) || (Array.isArray(obj) && obj.filter(notNull=>notNull).length === 0) || (typeof obj === 'object' && Object.keys(obj).length === 0 && obj.constructor === Object)
  : obj === null || obj === undefined;

export function distinctFromObjectArray(arr: any[], key: string) {
  const ret = [];
  const unique = [];
  for (const el of arr) {
    if (el) {
      if (unique.indexOf(el[key]) >= 0) continue;
      unique.push(el[key]);
      ret.push(el);
    }
  }
  return ret;
}

export function stableSort<T>(
  arr: T[],
  getKeys: ((item: T) => string | number | Date | string[] | number[] | Date[])[],
  directions: number[] = [],
  numeric: boolean[] = [],
): T[] {

  const convertToComparable = (value) => {
    if (!value || value?.length === 0 || (Array.isArray(value) && !value.filter(f =>f)?.length)) return '';
    if (typeof value === 'number') return value;
    if (value instanceof Date) return value.getTime();
    if (Array.isArray(value)) return convertToComparable(value[0]);
    return value.toString().toLowerCase();
  };

  return arr
    .map((item, index) => ({ item, index }))
    .sort((a, b) => {
      for (let i = 0; i < getKeys.length; i++) {
        const getKey = getKeys[i];
        const num = numeric[i];
        const keyA = num ? +getKey(a.item) : convertToComparable(getKey(a.item));
        const keyB = num ? +getKey(b.item) : convertToComparable(getKey(b.item));

        const direction = directions[i] || 1; // Default to ascending if direction is not provided

        if (keyA < keyB) return -1 * direction;
        if (keyA > keyB) return 1 * direction;
      }

      // If all keys are equal, maintain the original order
      return a.index - b.index;
    })
    .map((wrapped) => wrapped.item);
}

export function latestVersion(arr: any) {
  const sortedVersions = arr.sort((a, b) => {
    return a.version < b.version ? 1 : -1
  })  
  const latest = distinctFromObjectArray(sortedVersions, 'code').sort((a, b) => a.code < b.code ? 1 : -1);
  return latest;
}

export function diacriticless(s: string) {
  return s?.normalize('NFD').replace(/\p{Diacritic}/gu, '');
}

export function compareDiacriticless(a: string, b: string) {
  return diacriticless(a).localeCompare(diacriticless(b));
}

export function getDocumentNumber(doc: AbstractItemType, fromArray: CompilationModel[] = null, withEdit = true, split = false): string {
  doc = doc as CompilationModel;
  if (typeof doc === 'string' && /NRK/.test(doc) && !fromArray) {
    return doc;
  } else if (typeof doc === 'string' && /NRK/.test(doc)) {
    doc = fromArray.find(d => d.code === doc as unknown as string);
  } else if (typeof doc === 'string' && /^[a-f0-9]+$/i.test(doc)) {
    doc = fromArray.find(d => mongoId(d) === mongoId(doc));
  }
  if (doc && doc.nameOverride) {
    return doc.nameOverride;
  } else if (doc && doc.code) {
    const draft = (doc.version > 1 && doc.draft) ? '-DRAFT-' : '';
    const showvs = +doc.type !== CompilationTypeData.get('IN').id
      && +doc.type !== CompilationTypeData.get('PFIN').id
      && +doc.type !== CompilationTypeData.get('CN').id
      && +doc.type !== CompilationTypeData.get('PFCN').id
      && (doc.version !== undefined && doc.version !== null)
    //              VS1-01 <== Hyphen is non-braking hyphen &#x2011; !
    const editCount = '‑' + (doc.editCount ? [0,0].splice((''+doc.editCount).length).join('') + doc.editCount : '01');
    const code = split ? doc.code.replace(/^([^A-Za-z\-]*)([A-Za-z\-]+)(\d+)(.*)/, '$1$2 $3$4') : doc.code;
    return `${code}${draft}` + (showvs ? `VS${doc.version}${withEdit ? editCount : ''}` : '');
  }
  return '';
}

export async function waitForVariable(data: any, property = '', timeout = 1000, interval = 10) {
  return new Promise(resolve => {
    let count = timeout / interval;

    setTimeout(() => property ? data[property] && resolve(true) : data && resolve(true));

    const timer = setInterval(() => {
      if (property ? (data[property] !== null && data[property] !== undefined) : (data !== null && data !== undefined)) {
        clearInterval(timer);
        resolve(true);
      };
      if (--count <= 0) {
        clearInterval(timer);
        resolve(false);
      }
    }, interval);
  })
}

export function nrkPad(value: string | number, pad: number, fill = '0') {
  return value && value.toString().padStart(pad, fill);
}

export function isValidDate(date: any): boolean {
  // Check if the variable is a Date object
  if (date instanceof Date && !isNaN(date.getTime())) {
      return true;
  }
  
  // Check if the variable is a valid date string
  if (typeof date === 'string') {
      const parsedDate = new Date(date);
      if (!isNaN(parsedDate.getTime())) {
          return true;
      }
  }
  
  return false;
}

export function sanitizeDateToString(date: string | Date) {

  if ((date as any) instanceof Date && !isNaN((date as any).getTime())) return (date as Date).toISOString().replace(/T.*/, '');

  if (!date || date.toString().length < 4 ) return date === undefined ? undefined : null;

  // test for format 'YYYY-MM-DD'
  if (/\d\d\d\d-\d\d-\d\d/.test(date.toString())) return date;
  
  // test for format 'DD/MM/YYYY'
  let strDate = moment(date, 'DD/MM/YYYY');
  if (strDate.isValid()) return strDate.format('YYYY-MM-DD');

  // test for format 'DD-MMM-YYYY'
  strDate = moment(date, 'DD-MMM-YYYY');
  if (strDate.isValid()) return strDate.format('YYYY-MM-DD');

  return null;
}

export function sanitizeDate(date: string | Date) {
  const sanitized = sanitizeDateToString(date);
  return sanitized && new Date(sanitized);
}



// export function globalPriceAdjustments(el: any) {
//   // partial payment adjustments
//   let visible = DocConfig.get('invoicePayment').docs;
//   if (new RegExp('(' + visible.join('|') + ')').test(el['code']) && (el.invoicePayment == 1 || el.invoicePayment == 2)) {
//     if (el.lineItems && el.lineItems.net) {
//       if (el.lineItems.invoicePercent > 0) {
//           el.lineItems.net = el.lineItems.net * el.lineItems.invoicePercent / 100;
//           el.lineItems.piecePrice = el.lineItems.piecePrice * el.lineItems.invoicePercent / 100;
//           el.lineItems.price = el.lineItems.price * el.lineItems.invoicePercent / 100;
//       }
//     } else if (el.lineItems && el.lineItems.length) {
//       el.lineItems.forEach(it => {
//           if (it.invoicePercent > 0) {
//               if (!it.net) it.net = it.quantity * it.piecePrice;
//               it.net = it.net * it.invoicePercent / 100;
//               it.piecePrice = it.piecePrice * it.invoicePercent / 100;
//               it.price = it.price * it.invoicePercent / 100;
//           }
//       })
//     } else if (el.invoicePercent > 0) {
//         el.totalPrice = el.totalPrice * el.invoicePercent / 100;
//         el.displayUnitPrice = el.displayUnitPrice * el.invoicePercent / 100;
//     }
//   }

//   // discount adjustments
//   visible = DocConfig.get('discount').docs;
//   if (new RegExp('(' + visible.join('|') + ')').test(el['code'])) {
//       if (el.lineItems && el.lineItems.length) {
//           el.lineItems.forEach(it => {
//               if (it.discount > 0) {
//                   if (!it.net) it.net = it.quantity * it.piecePrice;
//                   it.net = it.net * (100 - it.discount) / 100;
//                   it.piecePrice = it.piecePrice * (100 - it.discount) / 100;
//                   it.price = it.price * (100 - it.discount) / 100;
//               }
//           })
//       } else if (el.discount > 0) {
//           el.totalPrice = el.totalPrice * (100 - el.discount) / 100;
//           el.displayUnitPrice = el.displayUnitPrice * (100 - el.discount) / 100;
//       }
//   }
// }

