<div *ngIf="!lite && !print && (abstractItem.changelog | typeof : 'string' : 'not')">
  <table class="nrk-table w-100 changelog" style="font-size: 0.85em">
    <thead>
      <th></th>
      <th style="text-align:center">Date</th>
      <th style="text-align:center">User</th>
      <th style="text-align:center">Type</th>
      <th *ngIf="abstractItem.documentStatus">Document #</th>
      <th *ngIf="abstractItem.documentStatus">Change</th>
      <th style="width: 100%">Entry</th>
      <th></th>
    </thead>
    <tbody>
      <ng-container *ngFor="let line of abstractItem.changelog; first as isFirst; index as idx">
        <tr *ngIf="isFirst && !disabled">
          <td><input type="checkbox" [(ngModel)]="line.showInPdf" title="show in PDF" [attr.disabled]="disabled ? true : null"/></td>
          <td style="text-align:center;white-space:nowrap;">{{line.date | date : 'yy-MM-dd'}}</td>
          <td style="text-align:center">{{getUserShort(line.user)}}</td>
          <td style="text-align:center">{{getChangeType(line.type)}}</td>
          <td *ngIf="abstractItem.documentStatus">{{line.documentNumber}}</td>
          <td *ngIf="abstractItem.documentStatus">{{line.change}}</td>
          <td>
            <table [class.changelog-items]="line.items && line.items.length > 1" style="width: 100%">
              <tr *ngFor="let item of line.items; last as lastItem; first as firstItem; index as idx">
                <td><button *ngIf="lastItem" class="nrk-doc-button" (click)="addLogItem(line)">+</button></td>
                <td><button *ngIf="line.items.length > 1" class="nrk-doc-button" (click)="removeLogItem(line, idx)">-</button></td>
                <td><ngx-doc-form-filter-select class="fullwidth" [(value)]="item.quickText" [options]="quickTextOptions" (valueChange)="line.dirty = true"></ngx-doc-form-filter-select></td>
                <td style="width:23em"><ngx-form-text [multiline]="1" [(value)]="item.text" (valueChange)="line.dirty = true"></ngx-form-text></td>
              </tr>
            </table>
          </td>
          <td><i style="cursor:pointer" class="ionicon ion-upload h5" (click)="showFileWidget[idx]=!showFileWidget[idx]" title="upload document"></i></td>
        </tr>
        <tr *ngIf="!isFirst || disabled">
          <td><input type="checkbox" [(ngModel)]="line.showInPdf" title="show in PDF" [attr.disabled]="disabled ? true : null"/></td>
          <td style="text-align:center;white-space:nowrap;">{{line.date | date : 'yy-MM-dd'}}</td>
          <td style="text-align:center">{{getUserShort(line.user)}}</td>
          <td style="text-align:center">{{getChangeType(line.type)}}</td>
          <td *ngIf="abstractItem.documentStatus">{{line.documentNumber}}</td>
          <td *ngIf="abstractItem.documentStatus">{{line.change}}</td>
          <td>
            <table [class.changelog-items]="line.items && line.items.length > 1" style="width: 100%">
              <tr *ngFor="let item of line.items">
                <td style="width: 20em">{{item.quickText}}</td>
                <td>{{item.text}}</td>
              </tr>
            </table>
          </td>
          <td><i style="cursor:pointer" class="ionicon ion-upload h5" (click)="showFileWidget[idx]=!showFileWidget[idx]" title="upload document"></i></td>
        </tr>
        <tr *ngIf="showFileWidget[idx]; else noWidget">
          <td colspan="8" style="border-top:none;"><ngx-form-upload [hideComment]="false" [hideCheckbox]="false" accept="application/pdf" url="/api/v1/upload" [(files)]="line.files"
            (completed)="uploadCompleted.emit()"></ngx-form-upload></td>
        </tr>
        <ng-template #noWidget>
          <tr *ngIf="line?.files?.length > 0">
            <td colspan="8" style="border-top:none;">
              <table class="changelog-files">
                <tr *ngFor="let file of line.files; first as firstFile">
                  <td><b>{{ firstFile ? 'Document(s):' : '' }}</b></td>
                  <td><span style="cursor:pointer" (click)="fileChecked(file)"><b>{{ file.checked ? '☑' : '☐' }}</b></span></td>
                  <td class="pseudo-link" (click)="api.downloadFile(file)">{{file.name}}</td>
                  <td>{{file.comment}}</td>
                </tr>
              </table>
            </td>
          </tr>
        </ng-template>
        <!-- <tr *ngIf="line.files.length && !showFileWidget || disabled">
          <td colspan="8" style="border-top:none;">
            <table class="changelog-files">
              <tr *ngFor="let file of line.files; first as firstFile">
                <td><b>{{ firstFile ? 'Documents:' : '' }}</b></td><td>{{file.name}}</td><td>{{file.comment}}</td>
              </tr>
            </table>
          </td>
        </tr> -->
      </ng-container>
    </tbody>
  </table>
</div>

<div *ngIf="!lite && print">
  <table class="nrk-table w-100 changelog" style="font-size: 0.85em">
    <thead>
      <th style="text-align:center">Date</th>
      <th style="text-align:center">User</th>
      <th style="text-align:center">Type</th>
      <th>Document #</th>
      <th>Change</th>
      <th style="width: 100%">Entry</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let line of abstractItem.changelog; first as isFirst">
        <ng-container *ngIf="line.showInPdf">
          <tr>
            <td style="text-align:center;white-space:nowrap;">{{line.date | date : 'yy-MM-dd'}}</td>
            <td style="text-align:center">{{getUserShort(line.user)}}</td>
            <td style="text-align:center">{{getChangeType(line.type)}}</td>
            <td>{{line.documentNumber}}</td>
            <td>{{line.change}}</td>
            <td>
              <table [class.changelog-items]="line.items.length > 1" style="width: 100%">
                <tr *ngFor="let item of line.items">
                  <td style="width: 20em;text-align:left">{{item.quickText}}</td>
                  <td style="text-align:left">{{item.text}}</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="8" style="border-top:none;">
              <table class="changelog-files">
                <tr *ngFor="let file of line.files; first as firstFile">
                  <td><b>{{ firstFile ? 'Document(s):' : '' }}</b></td><td>{{file.name}}</td><td>{{file.comment}}</td>
                </tr>
              </table>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>

<div *ngIf="lite" class="changelog-lite">
  <table>
    <thead>
      <tr>
        <th>Change date</th>
        <th *ngIf="compilation">Issue date</th>
        <th>User</th>
        <th>Document #</th>
        <th>Change</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let line of abstractItem.changelog; first as isFirst">
        <tr>
          <td style="width:10em">{{line.date | date : 'yyyy-MM-dd HH:mm'}}</td>
          <td *ngIf="compilation" style="width:10em">{{compilation.issueDate | date : 'yyyy-MM-dd HH:mm'}}</td>
          <td style="width:4em">{{getUserShort(line.user)}}</td>
          <td style="width:17em">{{line.documentNumber}}</td>
          <td>{{line.change}}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>


