
export class VersionEntryModel {
  id: string;
  number: number;
  ownerId: string;
}

export class VersionModel {
  // version: VersionEntryModel;
}
