import { UserModel } from './user';

export enum EWarehousingEntryType {
  log = 1,
  documentLog,
  message,
}

export interface IReadBy {
  user: UserModel,
  date: Date,
}

export class WarehousingModel {
  public _id: string;
  public entryType?: EWarehousingEntryType;
  public code?: string; // Document code
  public user: UserModel;
  public timestamp: Date;
  public subject?: string;
  public message: string = '';
  public recipient?: UserModel;
  public isReadBy?: IReadBy[];
  public deleted?: boolean;
  // public prev: string; // WarehousingModel;
  // public next: WarehousingModel;
  public children: WarehousingModel[];

  constructor() {
    this.children = [];
  }
}
