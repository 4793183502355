import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ngx-status-window',
  styles: [
    `.content {
      position: absolute;
      z-index: 999999999;
      right: 30px;
      bottom: 80px;
      width: 300px;
      /* height: 100px; */
      padding: 20px 20px 50px 20px;
      border: 1px solid darkgray;
      border-radius: 3px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      text-align: center;
      cursor: grab;
    }
    .close {
      position: absolute;
      top: 5px;
      right: 8px;
    }
    .log {
      color: #d33;
      overflow-wrap: break-word;
    }
    .message {
      overflow: hidden;
    }
    `,
  ],
  template: `
    <div ngxFreeDragging class="content" [style.display]="show ? 'block' : 'none'">
      <div class="close" (click)="show=false">×</div>
      <div class="log">
        <p *ngFor="let el of log">{{el}}</p>
      </div>
      <div class="message">
        {{message}}
      </div>
      <nb-progress-bar style="position:absolute;bottom:20px;width:260px" [value]="value">
      </nb-progress-bar>
    </div>
  `,
})
export class StatusWindowComponent implements OnInit, OnChanges {
  @Input() show: boolean = false;
  @Input() message: string = '';
  @Input() value: number = 0;
  @Input() log: string[] = [];

  anim: any;

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      if (this.value === -1 && !this.anim) {
        this.value = 0;
        let dir = 3;
        this.anim = setInterval( () => {
          this.value += dir
          if (this.value > 100 || this.value < 0) {
            dir = -dir;
          }
        }, 20)
      } else {
        if (this.anim) {
          clearInterval(this.anim);
          this.anim = null;
        }
      }
    }
  }

}
