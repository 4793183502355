import { CurrencyPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'currency' })
export class NrkCurrencyPipe extends CurrencyPipe implements PipeTransform {
//   transform(value: number | string | null | undefined): null;
//   transform(value: number | string | null | undefined): string | null {
//     return super.transform(value, 'USD', 'symbol', valueFormat);
//   }
    transform(value: string | number, currencyCode?: string, display?: string | boolean, digitsInfo?: string, locale?: string): string;
    transform(value: null, currencyCode?: string, display?: string | boolean, digitsInfo?: string, locale?: string): null;
    transform(value: string | number, currencyCode?: string, display?: string | boolean, digitsInfo?: string, locale?: string): string;
    transform(value: unknown, currencyCode?: unknown, display?: unknown, digitsInfo?: unknown, locale?: unknown): string {
        if (isNaN(value as number)) {
            console.error('NrkCurrencyPipe: Not a number: ', value);
            return undefined;
        }
        return super.transform(+value as any, currencyCode as any, display as any, digitsInfo as any, locale as any);
    }
        
}