import { ErrorHandler, Injectable, isDevMode } from '@angular/core';
import { ApiService } from './api.service';
import { EDebugType } from '../../@models/debug';
import * as StackTrace from 'stacktrace-js';
// import { RawSourceMap, SourceMapConsumer } from 'source-map';
import { StackFrame } from 'stacktrace-js';
import { environment } from '../../../environments/environment';

@Injectable()
export class ErrorService implements ErrorHandler {


    constructor (private api: ApiService) {}

  async handleError(error: any): Promise<void> {
    // console.log(JSON.stringify(error.message));
    // const mappedStackFrames = await this.mapStackTrace(error.stack);
    // this.api.debugLog('Auto Frontend Error', { msg: error.message, stack: mappedStackFrames }, EDebugType.frontend);
    console.error(error);
  }

  async mapStackTrace(stackTrace: string): Promise<StackFrame[] | string[]> {
    let stackFrames;
    try {
      stackFrames = await StackTrace.fromError(new Error(stackTrace));
    } catch (e) {
      console.error(e);
    }

    // const str = JSON.stringify(stackFrames);
    // console.log('stackFrames', str);

    return environment.production ? stackFrames : stackTrace.split('\n');

    // console.log('stack frames', stackFrames, stackTrace);
  
    // const sourceMappingPromises = stackFrames.map(async (frame) => {
    //   // Extract the production file name and line/column numbers from the stack frame
    //   const fileName = frame.fileName;
    //   const lineNumber = frame.lineNumber;
    //   const columnNumber = frame.columnNumber;
  
    //   // Load the corresponding source map file
    //   const mapResponse = await fetch(fileName + '.map');
    //   const mapFile = await mapResponse.text();
  
    //   // Parse the source map file
    //   const sourceMap: RawSourceMap = JSON.parse(mapFile);
    //   const sourceMapConsumer = await new SourceMapConsumer(sourceMap);
  
    //   // Use the source map consumer to retrieve the original source file name and line/column numbers
    //   const originalPosition = sourceMapConsumer.originalPositionFor({ line: lineNumber, column: columnNumber });
  
    //   // Update the stack frame with the original source file name and line/column numbers
    //   frame.fileName = originalPosition.source;
    //   frame.lineNumber = originalPosition.line;
    //   frame.columnNumber = originalPosition.column;

    //   console.log('frame', frame);
  
    //   return frame;
    // });
  
    // return Promise.all(sourceMappingPromises);
  }
}