import { Injectable } from '@angular/core';
import * as uniqid from 'uniqid';
import { EventService } from './eventService';

@Injectable({
  providedIn: 'root',
})
export class TabCoordinatorService {
  private readonly channel: BroadcastChannel;
  private readonly tabId = uniqid();
  private readonly checkInterval: number = 1000;
  private tabIdQueue = [];

  constructor(private event: EventService) {
    this.channel = new BroadcastChannel('tab_coordinator_channel');

    if (!this.masterTabId) {
      this.masterTabId = this.tabId;
    } else {
      this.addSlave(this.tabId);
    }

    console.log('TabCoordinatorService - ', this.tabId);
    
    this.channel.addEventListener('message', this.handleMessage.bind(this));
    window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this));

    this.ping();
    setInterval(() => this.queueChecker(this.tabId), this.checkInterval);
  }

  public get isMasterTab(): boolean {
    return this.masterTabId === this.tabId;
  }

  private get masterTabId(): string {
    return localStorage.getItem('masterTabId');
  }

  private set masterTabId(tabId: string) {
    localStorage.setItem('masterTabId', tabId);
  }

  public makeMaster() {
    this.addSlave(this.masterTabId);
    this.masterTabId = this.tabId;
    this.event.emit({ name: 'tab-coordinator', data: 'masterTabEvent' })
    this.channel.postMessage({
      type: 'makeSlave'
    })
  }

  private get slaves(): string[] {
    return JSON.parse(localStorage.getItem('slaveTabIds')) || [];
  }

  private set slaves(tabIds: string[]) {
    localStorage.setItem('slaveTabIds', JSON.stringify(tabIds));
  }

  private handleMessage(event: MessageEvent): void {
    const message = event.data;
    // console.log(`TabCoordinatorService - Received message: ${JSON.stringify(message)}`);

    switch (message.type) {
      case 'ping':
        // console.log('TabCoordinatorService - ping => send pong', this.tabId);
        this.channel.postMessage({ 
          type: 'pong', 
          tabId: this.tabId 
        }); 
        break
      case 'pong':
        // console.log('TabCoordinatorService - pong', message)
        this.tabIdQueue.push(message.tabId)
        break
      case 'makeSlave':
        this.event.emit({ name: 'tab-coordinator', data: 'makeSlave' })
        break;
        
    }
  }

  private handleBeforeUnload(): void {
    console.log(`TabCoordinatorService - Tab closed`);
    if (this.isMasterTab) localStorage.removeItem('masterTabId');
    this.removeSlave(this.tabId);
  }

  private addSlave(tabId: string) {
    if (!this.slaves.includes(this.tabId)) this.slaves = [...this.slaves, tabId];
  }

  private removeSlave(tabId: string) {
    this.slaves = this.slaves.filter(f => f !== tabId);
  }

  private ping() {
    // console.log('TabCoordinatorService - send ping');
    this.channel.postMessage({
      type: 'ping',
    })
  }

  private queueChecker(tabId: string) {
    if (!this.tabIdQueue?.length || !this.tabIdQueue.includes(this.masterTabId)) {
      this.masterTabId = tabId;
      
      this.event.emit({ name: 'tab-coordinator', data: 'masterTabEvent' })
    }
    this.event.emit({ name: 'tab-coordinator', data: 'queueChecker' })
    this.tabIdQueue = [tabId];
    this.ping();
    // console.log('TabCoordinatorService - ', this.isMasterTab, this.masterTabId, this.tabIdQueue, this.tabId, tabId);
  }
 

//   constructor() {
//     this.channel = new BroadcastChannel('tab_coordinator_channel');
//     this.tabId = uniqid();

//     this.channel.addEventListener('message', this.handleMessage.bind(this));
//     window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this));

//     this.addSlaveTab(this.tabId);

//     this.startMasterTabCheck();

//     console.log(`TabCoordinatorService - Tab ID: ${this.tabId}`);
//   }

//   private handleMessage(event: MessageEvent): void {
//     const message = event.data;
//     console.log(`TabCoordinatorService - Received message: ${JSON.stringify(message)}`);

//     if (message.type === 'STATUS') {
//       const hasMasterTab = message.hasMasterTab;
//       console.log(`TabCoordinatorService - Status received: Has Master Tab - ${hasMasterTab}`);

//       if (!hasMasterTab) {
//         this.setAsMasterTab();
//         console.log(`TabCoordinatorService - Set as Master Tab`);
//       }
//     } else if (message.type === 'REQUEST_STATUS') {
//       this.channel.postMessage({
//         type: 'STATUS',
//         hasMasterTab: this.isMasterTab(),
//       });
//       console.log(`TabCoordinatorService - Sent Status`);
//     }
//   }

//   private handleBeforeUnload(): void {
//     if (this.isMasterTab()) {
//       console.log(`TabCoordinatorService - Master Tab closed`);
//       this.removeSlaveTab(this.tabId); // Remove the current tab as it is closing
//       this.reassignMasterTab();
//     }
//   }

//   private startMasterTabCheck(): void {
//     setInterval(() => {
//       if (!this.isMasterTab()) {
//         console.log(`TabCoordinatorService - No Master Tab found, reassigning...`);
//         this.reassignMasterTab();
//       } else {
//         console.log(`TabCoordinatorService - This is the Master Tab... ${this.isMasterTab()}`);
//       }
//     }, this.checkInterval);
//   }

//   private reassignMasterTab(): void {
//     const slaveTabs = this.getSlaveTabs();
//     if (slaveTabs.length > 0) {
//       // const oldestSlaveTab = slaveTabs[0];
//       // this.setAsMasterTab(oldestSlaveTab.id);
//       const latestSlaveTab = slaveTabs.reverse()[0];
//       this.setAsMasterTab(latestSlaveTab.id);
//       console.log(
//         `TabCoordinatorService - Set oldest Slave Tab (${latestSlaveTab.id}) as new Master Tab`
//       );
//     } else {
//       this.setAsMasterTab();
//       console.log(`TabCoordinatorService - No Slave Tabs available, assigned self as Master Tab`);
//     }
//   }

//   private getSlaveTabs(): StorageTab[] {
//     return JSON.parse(localStorage.getItem('slaveTabs') || '[]');
//   }

//   private setSlaveTabs(slaveTabs: StorageTab[]): void {
//     localStorage.setItem('slaveTabs', JSON.stringify(slaveTabs));
//   }

//   private addSlaveTab(tabId: string): void {
//     const slaveTabs = this.getSlaveTabs();
//     if (!slaveTabs.some((tab) => tab.id === tabId)) {
//       slaveTabs.push({ id: tabId });
//       this.setSlaveTabs(slaveTabs);
//       console.log(`TabCoordinatorService - Added Slave Tab (${tabId})`);
//     }
//   }

//   private removeSlaveTab(tabId: string): void {
//     const slaveTabs = this.getSlaveTabs();
//     const index = slaveTabs.findIndex((t) => t.id === tabId);
//     if (index !== -1) {
//       slaveTabs.splice(index, 1);
//       this.setSlaveTabs(slaveTabs);
//       console.log(`TabCoordinatorService - Removed Slave Tab (${tabId})`);
//     }
//   }

//   public isMasterTab(): boolean {
//     const masterTabId = localStorage.getItem('masterTabId');
//     console.log(`TabCoordinatorService - masterTabId ${masterTabId} - tabId ${this.tabId}`)
//     return masterTabId === this.tabId;
//   }

//   private setAsMasterTab(newMasterTabId?: string): void {
//     localStorage.setItem('masterTabId', newMasterTabId || this.tabId);
//     console.log(`TabCoordinatorService - Set Tab (${newMasterTabId || this.tabId}) as Master Tab`);
//   }
// }

// interface StorageTab {
//   id: string;
// }

}