import {Injectable} from '@angular/core';


import {RequestArrayModel} from '../../@models/requests/requestArray';
import {HttpClient} from '@angular/common/http';
import {Subject, Observable, firstValueFrom, lastValueFrom, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

import {
  ActivityModel,
  BusinessModel,
  BusinessUnitModel,
  ContactModel,
  EBusinessType,
  EContactFieldType,
  EContactType, EmptyActivityContentModel, FileModel, MaterialModel, OfferListModel, ProjectListModel,
  ProjectModel,
  UserModel,
} from '../../@models';
import {RequestObjectModel} from '../../@models/requests/requestObject';
import {RegionModel} from '../../@models/region';
import {ProjectSummaryModel} from '../../@models/projectSummary';
import {saveAs} from 'file-saver';
import * as moment from 'moment';
import { CoreModel } from '../../@models/erp/core';
import { BaseProductModel, DownloadType } from '../../@models/erp/baseProduct';
import { FinishedProductModel, NameTable } from '../../@models/erp/finishedProduct';
import { PriceItem, PriceMatrixModel } from '../../@models/erp/priceMatrix';
import { CacheType, CompilationModel, IPalletRef } from '../../@models/erp/compilation';
import { AbstractItemType } from '../../pages/erp/abstractItem';
import { DownloadConfig } from '../../pages/erp/document/document-printer/document-printer.component';
import { CompilationDataModel } from '../../@models/erp/compilationData';
import { ChangelogEntry } from '../../pages/erp/shared/changelog/changelog.component';
import { AssociatedPfspo, LineItemModel } from '../../@models/erp/lineItem';
import { mongoId } from '../common/global';
import { SpoBoxModel } from '../../@models/erp/spoBox';
import { WarehousingModel } from '../../@models/warehousing';
import { DebugModel, EDebugType } from '../../@models/debug';
import { SampleLayoutComponent } from '../../@theme/layouts';
import { TreeService } from './tree.service';
@Injectable()
export class ApiService {
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private mErrorSubject: Subject<any> = new Subject<any>();

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private mBusinessSubject: Subject<BusinessModel[]>;
  private mBusinessObject: any = {};

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private mUsersSubject: Subject<UserModel[]>;
  private mUsersObject: any = {};
  private mUsers: UserModel[] = null;

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private mActivitiesSubject: Subject<ActivityModel[]>;
  private mActivitesObject: any = {};

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private mRegionsSubject: Subject<RegionModel[]>;
  private mRegionsObject: any = {};

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private mBusinessUnitsSubject: Subject<BusinessUnitModel[]>;

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private mMaterialSubject: Subject<MaterialModel[]>;
  private mMaterials: MaterialModel[] = null;

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private mProjects: any = {};
  // private mSubprojects: any = {};
  private mBusinessUnits: any = {};

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private http: HttpClient,
  ) {  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // getter

  public get token(): string {
    const v = JSON.parse(localStorage.getItem('auth_app_token'));
    return v != null ? v.value : null;
  }

  public get business(): Observable<BusinessModel[]> {
    if (this.mBusinessSubject == null) this.reloadBusiness();
    return this.mBusinessSubject.asObservable();
  }
de
  // public get users(): Observable<UserModel[]> {
  //   if (this.mUsersSubject == null) this.reloadUsers();
  //   return this.mUsersSubject.asObservable();
  // }

  // public get users(): UserModel[] {
  //   if (!this.mUsers) this.reloadUsers(); // async !
  //   return this.mUsers;
  // }

  public get activities(): Observable<ActivityModel[]> {
    if (this.mActivitiesSubject == null) this.reloadActivities();
    return this.mActivitiesSubject.asObservable();
  }

  public get materials(): MaterialModel[] {
    return this.mMaterials;
  }

  public get error(): Observable<any> {
    return this.mErrorSubject.asObservable();
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // project stuff

  public getProject(id: string): ProjectModel {
    return this.mProjects[id];
  }

  /* public getSubproject(id: string): SubprojectModel {
    return this.mSubprojects[id];
  }*/

  public getBusinessUnit(id: string): BusinessUnitModel {
    return this.mBusinessUnits[id]
  }

  public getBusiness(id: string): BusinessModel {
    return this.mBusinessObject[id];
  }

  public getRegion(id: string): RegionModel {
    return this.mRegionsObject[id];
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public reloadBusiness(filter: {active?: boolean} = {}): Observable<BusinessModel[]> {
    if (this.mBusinessSubject == null) {
      this.mBusinessSubject = new Subject<BusinessModel[]>();
      this.mBusinessSubject.subscribe((b) => b.reduce((r, i) => r[i._id] = i, {}));
    }

    const httpParams = [];
    if (filter != null) {
      if (filter.active != null) httpParams.push('active=' + String(filter.active));
    }

    this.http.get('/api/v1/business?' + httpParams.join('&'), {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<BusinessModel>).body))
      .subscribe({ next: data => {
        Object.assign(this.mBusinessObject, data.reduce((p, c) => {
          p[c._id] = c;
          return p;
        }, {}));

        Object.assign(this.mProjects, data.reduce((px, cx) => {
          Object.assign(px, cx.projects.reduce((p, c) => {
            p[c._id] = c;
            return p;
          }, {}));
          return px;
        }, {}));
        this.mBusinessSubject.next(data.sort((a, b) =>  a.name.localeCompare(b.name)));
      }, 
      error: e => this.mErrorSubject.next(e)
    });
    return this.mBusinessSubject.asObservable()
  }

  public loadBusiness(id: string): Subject<BusinessModel> {
    const businessSubject = new Subject<BusinessModel>();
    if (id != null) {
      this.http.get('/api/v1/business/' + id, {headers: {authorization: this.token}})
        .pipe(map(response => (response as RequestObjectModel<BusinessModel>).body))
        .subscribe({ next: data => {
          businessSubject.next(data);
        }, error: e => this.mErrorSubject.next(e)});
    }
    return businessSubject; // .asObservable();
  }

  public saveBusiness(item: BusinessModel): Observable<BusinessModel> {
    const businessSubject = new Subject<BusinessModel>();
    this.http.request(item._id == null ? 'post' : 'put', '/api/v1/business',
      {headers: {authorization: this.token}, body: new RequestObjectModel(item) })
      .pipe(map(response => (response as RequestObjectModel<BusinessModel>).body))
      .subscribe({next: data => {
        businessSubject.next(data);
        this.reloadBusiness();
      }, error: e => this.mErrorSubject.next(e)});
    return businessSubject.asObservable()
  }

  public reloadUsers(): Promise<UserModel[]> {
    // if (this.mUsersSubject == null) {
    //   this.mUsersSubject = new Subject<UserModel[]>();
    //   this.mUsersSubject.subscribe((b) => b.reduce((r, i) => r[i._id] = i, {}));
    // }
    // console.log('reload user...')
    // console.trace();
    // this.http.get('/api/v1/user', {headers: {authorization: this.token}})
    //   .pipe(map(response => (response as RequestArrayModel<UserModel>).body))
    //   .subscribe({ next: data => {
    //     this.mUsersSubject.next(data);
    //     this.mUsers = data;
    //   }, error: e => this.mErrorSubject.next(e)});
    // return this.mUsersSubject.asObservable()
    return firstValueFrom(this.http.get('/api/v1/user', {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<UserModel>).body)))
  }

  public loadMe(all: boolean = false): Observable<UserModel> {
    const userSubject = new Subject<UserModel>();
    this.http.get('/api/v1/user/me' + (all ? '?all=true' : ''), {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<UserModel>).body))
      .subscribe({ next: data => {
        userSubject.next(data);
      }, error: e => this.mErrorSubject.next(e)});
    return userSubject.asObservable();
  }

  public reloadContacts(filter: {
    type?: EBusinessType,
    contactType?: EContactType,
    business?: string,
  } = {}): Observable<ContactModel[]> {
    const httpParams = [];
    if (filter != null) {
      if (filter.type != null) httpParams.push('type=' + String(filter.type));
      if (filter.contactType != null) httpParams.push('contacttype=' + String(filter.contactType));
      if (filter.business != null) httpParams.push('business=' + String(filter.business));
    }
    const subject = new Subject<ContactModel[]>();
    this.http.get('/api/v1/contact?' + httpParams.join('&'), {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<ContactModel>).body))
      .pipe(map(data => data.map(x => {
        const title: any = (x != null && x.fields != null) ?
          x.fields.find(y => Number(y.type) === Number(EContactFieldType.JobTitle)) || {} : {};
        return {
          _id: x._id,
          type: x.type,
          title: x.title,
          fields: x.fields,
          business: x.business,
          addressName: x.addressName,
          displayTitle: `${x.business != null ? x.business.name : ''}: ${x.title} (${title.value || ''})`,
        }
      }),
      ))
      .subscribe({ next: data => {
        subject.next(data)
      }, error: e => this.mErrorSubject.next(e)});
    return subject.asObservable();
  }

  public get currentUser(): Observable<UserModel> {
    const userSubject = new Subject<UserModel>();

    return userSubject.asObservable();
  }

  public loadUser(id: string): Observable<UserModel> {
    const userSubject = new Subject<UserModel>();
    this.http.get('/api/v1/contact/' + id, {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<UserModel>).body))
      .subscribe({next: data => {
        /* Object.assign(this.mBusinessUnits, data.reduce((px, cx) => {
          Object.assign(px, cx.businessUnits.reduce((p, c) => {
            p[c._id] = c;
            return p;
          }, {}));
          return px;
        }, {}));*/
        userSubject.next(data);
      }, error: e => this.mErrorSubject.next(e)});
    return userSubject.asObservable();
  }

  public saveUser(item: UserModel): Observable<UserModel> {
    const userSubject = new Subject<UserModel>();
    console.log('save user', item);
    this.http.request(item._id == null ? 'post' : 'put', '/api/v1/user',
      {headers: {authorization: this.token}, body: new RequestObjectModel(item) })
      .pipe(map(response => (response as RequestObjectModel<UserModel>).body))
      .subscribe({next:data => {
        userSubject.next(data);
        this.reloadUsers();
      }, error: e => this.mErrorSubject.next(e)});
    return userSubject.asObservable();
  }

  public reloadNews(all: boolean = false): Observable<ActivityModel[]> {
    if (this.mActivitiesSubject == null) {
      this.mActivitiesSubject = new Subject<ActivityModel[]>();
      this.mActivitiesSubject.subscribe((b) => b.reduce((r, i) => r[i._id] = i, {}));
    }
    this.http.get('/api/v1/activity/news' + (all ? '?all=true' : '') , {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<ActivityModel>).body))
      .subscribe({ next: data => {
        this.mActivitiesSubject.next(data);
      }, error: e => this.mErrorSubject.next(e)});
    return this.mActivitiesSubject.asObservable();
  }


  public reloadActivities(allUsers = false, filter = null): Observable<ActivityModel[]> {
    if (this.mActivitiesSubject == null) {
      this.mActivitiesSubject = new Subject<ActivityModel[]>();
      this.mActivitiesSubject.subscribe((b) => b.reduce((r, i) => r[i._id] = i, {}));
    }
    const req = [];
    if (allUsers) req.push('allUsers=1');
    if (filter) req.push('filter=' + encodeURIComponent(JSON.stringify(filter)));
    this.http.get('/api/v1/activity?' + req.join('&'), {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<ActivityModel>).body))
      .subscribe({ next: data => {
        this.mActivitiesSubject.next(data);
      }, error: e => this.mErrorSubject.next(e)});
    return this.mActivitiesSubject.asObservable();
  }

  public loadActivity(id: string): Observable<ActivityModel> {
    const activitySubject = new Subject<ActivityModel>();
    if (id != null) {
      this.http.get('/api/v1/activity/detail/' + id, {headers: {authorization: this.token}})
        .pipe(map(response => (response as RequestObjectModel<ActivityModel>).body))
        .subscribe({ next: data => {
          activitySubject.next(data);
        }, error: e => this.mErrorSubject.next(e)});
    }
    return activitySubject; // .asObservable();
  }

  public saveActivity(item: ActivityModel, edit: boolean = false): Observable<ActivityModel> {
    const activitySubject = new Subject<ActivityModel>();
    const param = item._id == null ? '' : `/${edit}`;
    this.http.request(item._id == null ? 'post' : 'put', '/api/v1/activity' + param,
      {headers: {authorization: this.token}, body: new RequestObjectModel(item) })
      .pipe(map(response => (response as RequestObjectModel<ActivityModel>).body))
      .subscribe({ next: data => {
        activitySubject.next(data);
        this.reloadActivities();
      }, error: e => this.mErrorSubject.next(e)});
    return activitySubject.asObservable();
  }

  public deleteActivity(id: string) {
    return firstValueFrom(this.http.request('delete', '/api/v1/activity/' + id,
      { headers: { authorization: this.token } }))
  }

  public reloadRegions(): Observable<RegionModel[]> {
    if (this.mRegionsSubject == null) {
      this.mRegionsSubject = new Subject<RegionModel[]>();
    }
    this.http.get('/api/v1/region', {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<RegionModel>).body))
      .subscribe({ next: data => {
        Object.assign(this.mRegionsObject, data.reduce((p, c) => {
          p[c._id] = c;
          return p;
        }, {}));
        this.mRegionsSubject.next(data);
      }, error: e => this.mErrorSubject.next(e)});
    return this.mRegionsSubject.asObservable();
  }

  public saveRegion(item: RegionModel): Observable<RegionModel> {
    const regionSubject = new Subject<RegionModel>();
    this.http.request(item._id == null ? 'post' : 'put', '/api/v1/region',
      {headers: {authorization: this.token}, body: new RequestObjectModel(item) })
      .pipe(map(response => (response as RequestObjectModel<RegionModel>).body))
      .subscribe({ next: data => {
        regionSubject.next(data);
        this.reloadRegions();
      }, error: e => this.mErrorSubject.next(e)});
    return regionSubject.asObservable();
  }


  public reloadBusinessUnits(): Observable<BusinessUnitModel[]> {
    if (this.mBusinessUnitsSubject == null) {
      this.mBusinessUnitsSubject = new Subject<BusinessUnitModel[]>();
    }
    this.http.get('/api/v1/business_unit', {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<BusinessUnitModel>).body))
      .subscribe({ next: data => {
        Object.assign(this.mBusinessUnits, data.reduce((p, c) => {
          p[c._id] = c;
          return p;
        }, {}));
        this.mBusinessUnitsSubject.next(data);
      }, error: e => this.mErrorSubject.next(e)});
    return this.mBusinessUnitsSubject.asObservable();
  }

  public saveBusinessUnit(item: BusinessUnitModel): Observable<BusinessUnitModel> {
    const businessUnitSubject = new Subject<BusinessUnitModel>();
    this.http.request(item._id == null ? 'post' : 'put', '/api/v1/business_unit',
      {headers: {authorization: this.token}, body: new RequestObjectModel(item) })
      .pipe(map(response => (response as RequestObjectModel<BusinessUnitModel>).body))
      .subscribe({ next: data => {
        businessUnitSubject.next(data);
        this.reloadBusinessUnits();
      }, error: e => this.mErrorSubject.next(e)});
    return businessUnitSubject.asObservable();
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public reloadMaterials(): Observable<MaterialModel[]> {
    if (this.mMaterialSubject == null) {
      this.mMaterialSubject = new Subject<MaterialModel[]>();
    }
    this.http.get('/api/v1/material', {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<MaterialModel>).body))
      .subscribe({ next: data => {
        this.mMaterialSubject.next(data);
        this.mMaterials = data;
      }, error: e => this.mErrorSubject.next(e)});
    return this.mMaterialSubject.asObservable();
  }

  public saveMaterial(item: MaterialModel): Observable<MaterialModel> {
    const materialSubject = new Subject<MaterialModel>();
    this.http.request(item._id == null ? 'post' : 'put', '/api/v1/material',
      {headers: {authorization: this.token}, body: new RequestObjectModel(item) })
      .pipe(map(response => (response as RequestObjectModel<MaterialModel>).body))
      .subscribe({ next: data => {
        materialSubject.next(data);
        this.reloadBusinessUnits();
      }, error: e => this.mErrorSubject.next(e)});
    return materialSubject.asObservable();
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public populateUser(id: string): Observable<UserModel> {
    const ret = new Subject<UserModel>();
    if (!this.mUsersObject.hasOwnProperty(id)) {
      this.loadUser(id).subscribe((user) => {
        this.mUsersObject[user._id] = user;
        ret.next(user)
      });
    } else {
      setTimeout(() => ret.next(this.mUsersObject[id]));
    }
    return ret;
  }

  public populateBusiness(id: string): Observable<BusinessModel> {
    // console.log('populateBusiness');
    if (!this.mBusinessObject.hasOwnProperty(id)) {
      if (this.mBusinessObject.hasOwnProperty(id + '_subject')) {
        return this.mBusinessObject[id + '_subject'];
      } else {
        const s = this.mBusinessObject[id + '_subject'] = this.loadBusiness(id);
        s.subscribe( (business) => {
          this.mBusinessObject[id] = business;
          s.next(business);
        });
        return s;
      }
    } else {
      const s = new Subject<BusinessModel>();
      setTimeout(() => s.next(this.mBusinessObject[id]));
      return s.asObservable();
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public myOfferList(): Observable<ActivityModel[]> {
    return this.http.get('/api/v1/activity/myoffers', {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<ActivityModel>).body));
  }


  public offerList() {
    return this.http.get('/api/v1/report/offerlist', {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<OfferListModel>).body));
  }


  public updateOffer(item: any): Promise<boolean> {
    return firstValueFrom(this.http.put('/api/v1/activity/offer/' + item._id, {text: item.content.text, status: item.content.status},
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<boolean>).body)))
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public projectSummary(id: string): Observable<ProjectSummaryModel[]> {
    return this.http.get('/api/v1/project/summary/' + id, {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<ProjectSummaryModel>).body));
  }


  public taskList(): Observable<any[]> {
    return this.http.get('/api/v1/task', {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<any[]>).body));
  }

  public updateTask(value: boolean, task: any): Promise<boolean> {
    return firstValueFrom(this.http.put('/api/v1/task', {task, value}, {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<boolean>).body)))
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public projectList(): Observable<ProjectListModel[]> {
    return this.http.get('/api/v1/project/list',  {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<ProjectListModel>).body));
  }

  public projects(): Observable<ProjectModel[]> {
    return this.http.get('/api/v1/project',  {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<ProjectModel>).body));
  }

  public myProjectList(): Observable<ProjectListModel[]> {
    return this.http.get('/api/v1/project/',  {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<ProjectListModel>).body));
  }

  public getProjectFromServer(id: string): Promise<ProjectModel> {
    return this.http.get('/api/v1/project/' + id,  {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<ProjectModel>).body))
      .toPromise();
  }

  public saveProject(item: ProjectModel): Observable<ProjectModel> {
    const projectSubject = new Subject<ProjectModel>();
    this.http.request(item._id == null ? 'post' : 'put', '/api/v1/project',
      {headers: {authorization: this.token}, body: new RequestObjectModel(item) })
      .pipe(map(response => (response as RequestObjectModel<ProjectModel>).body))
      .subscribe({ next: data => {
        projectSubject.next(data);
      }, error: e => this.mErrorSubject.next(e)});
    return projectSubject.asObservable();
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public salesReport(filter: {
    regions: string[],
    rsms: string[],
    bus: string[],
    customers: string[],
    dateFrom: string,
    dateTo: string,
    activitytypes: [],
  }): Observable<any[]> {

    const httpParams = [];

    if (filter.regions != null && filter.regions.length > 0)
      httpParams.push('regions=' + String(filter.regions.join(';')));
    if (filter.rsms != null && filter.rsms.length > 0)
      httpParams.push('rsms=' + String(filter.rsms.join(';')));
    if (filter.bus != null && filter.bus.length > 0)
      httpParams.push('bus=' + String(filter.bus.join(';')));
    if (filter.customers != null && filter.customers.length > 0)
      httpParams.push('customers=' + String(filter.customers.join(';')));
    if (filter.dateFrom != null )
      httpParams.push('datefrom=' + String(filter.dateFrom));
    if (filter.dateTo != null )
      httpParams.push('dateto=' + String(filter.dateTo));
    if (filter.activitytypes != null && filter.activitytypes.length > 0)
      httpParams.push('activitytypes=' + String(filter.activitytypes.join(';')));

    return this.http.get('/api/v1/project/salesreport?' + httpParams.join('&'),
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<any[]>).body));
  }

  public salesReportActivities(ids: string[], filter: {
    regions: string[],
    rsms: string[],
    bus: string[],
    customers: string[],
    dateFrom: string,
    dateTo: string,
    activitytypes: [],
    hideTopics: boolean,
  }): Observable<any[]> {

    const httpParams = [];
    if (ids) httpParams.push('ids=' + String(ids.join(';')));
    if (filter.regions != null && filter.regions.length > 0)
      httpParams.push('regions=' + String(filter.regions.join(';')));
    if (filter.rsms != null && filter.rsms.length > 0)
      httpParams.push('rsms=' + String(filter.rsms.join(';')));
    if (filter.bus != null && filter.bus.length > 0)
      httpParams.push('bus=' + String(filter.bus.join(';')));
    if (filter.customers != null && filter.customers.length > 0)
      httpParams.push('customers=' + String(filter.customers.join(';')));
    if (filter.dateFrom != null )
      httpParams.push('datefrom=' + String(filter.dateFrom));
    if (filter.dateTo != null )
      httpParams.push('dateto=' + String(filter.dateTo));
    if (filter.activitytypes != null && filter.activitytypes.length > 0)
      httpParams.push('activitytypes=' + String(filter.activitytypes.join(';')));
    if (filter.hideTopics != null )
      httpParams.push('hidetopics=' + String(filter.hideTopics));


    return this.http.get('/api/v1/project/salesreport/activities?' + httpParams.join('&'),
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<any[]>).body));
  }


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public async downloadVisitReport(item: ActivityModel,
    downloadType: DownloadType = DownloadType.merged): Promise<any> {

    // console.log('download visit report', downloadType);

    const company = 'NRKA'; // item.business.name;
    const name = item.owner.short;
    const seq = (item.seq || 0).toString().padStart(4, '0');
    const v = (item.version || 0).toString().padStart(2, '0');
    const date = moment(item.date).utc().format('YYMMDD');
    const short = EmptyActivityContentModel.getShort(item.content.type);
    const version = `${date} - ${item.business.name} - ${short}${name}${company}${seq}-VS${v}`;

    if (downloadType === DownloadType.preview) {
      return firstValueFrom(this.http.get(`/api/v1/report/vr/${item._id}/${downloadType}/${version}.pdf`,
        {headers: {authorization: this.token}})
        .pipe(map(response => (response as RequestObjectModel<{html: string}>).body)))
    }

    const data = await this.http.get(
      `/api/v1/report/vr/${item._id}/${downloadType}/${version}.pdf`,
      {headers: {authorization: this.token}, responseType: 'blob'}).toPromise();
    const ext = downloadType === DownloadType.document || downloadType === DownloadType.merged ? '.pdf' : ' - Documents.zip';
    // console.log('visit report data', data);
    saveAs(data, version + ext);
    return data
  }

  public async downloadProjectlist(): Promise<boolean> {
    const data = await this.http.get(
      '/api/v1/report/projectlist',
      {headers: {authorization: this.token}, responseType: 'blob'}).toPromise();
    saveAs(data, 'ProjectList.xlsx');
    return true;
  }

  public downloadSalesReport(filter: {
    regions: string[],
    rsms: string[],
    bus: string[],
    customers: string[],
    dateFrom: string,
    dateTo: string,
    activitytypes: [],
    withOverview: boolean,
    plannedActivityReport: number,
    hideActivityByProject?: boolean,
    overviewReport?: boolean,
    xls?: boolean,
    user?: string,
    showPreparedByOwnerOnly?: boolean,
  }): Observable<any> {
    const httpParams = [];
    if (filter.regions != null && filter.regions.length > 0)
      httpParams.push('regions=' + String(filter.regions.join(';')));
    if (filter.rsms != null && filter.rsms.length > 0)
      httpParams.push('rsms=' + String(filter.rsms.join(';')));
    if (filter.bus != null && filter.bus.length > 0)
      httpParams.push('bus=' + String(filter.bus.join(';')));
    if (filter.customers != null && filter.customers.length > 0)
      httpParams.push('customers=' + String(filter.customers.join(';')));
    if (filter.dateFrom != null )
      httpParams.push('datefrom=' + String(filter.dateFrom));
    if (filter.dateTo != null )
      httpParams.push('dateto=' + String(filter.dateTo));
    if (filter.activitytypes != null && filter.activitytypes.length > 0)
      httpParams.push('activitytypes=' + String(filter.activitytypes.join(';')));
    if (filter.withOverview)
      httpParams.push('withOverview=true');
    httpParams.push('plannedActivityReport=' + +filter.plannedActivityReport);
    if (filter.overviewReport)
      httpParams.push('overviewReport=true');
    if (filter.user)
      httpParams.push('user=' + String(filter.user));
    if (filter.xls)
      httpParams.push('xls=true');
    if (filter.hideActivityByProject)
      httpParams.push('hideActivityByProject=true');
    if (filter.showPreparedByOwnerOnly)
      httpParams.push('showPreparedByOwnerOnly=true');
    
    return this.http.get(
      '/api/v1/report/sales?' + httpParams.join('&'),
      {headers: {authorization: this.token}, responseType: 'blob'});
  }

  public downloadSalesPresentation(filter: {
    regions: string[];
    rsms: string[];
    bus: string[];
    customers: string[];
    dateFrom: string;
    dateTo: string;
    activitytypes: [];
    hideTopics: boolean }): Observable<any> {

    const httpParams = [];
    if (filter.regions != null && filter.regions.length > 0)
      httpParams.push('regions=' + String(filter.regions.join(';')));
    if (filter.rsms != null && filter.rsms.length > 0)
      httpParams.push('rsms=' + String(filter.rsms.join(';')));
    if (filter.bus != null && filter.bus.length > 0)
      httpParams.push('bus=' + String(filter.bus.join(';')));
    if (filter.customers != null && filter.customers.length > 0)
      httpParams.push('customers=' + String(filter.customers.join(';')));
    if (filter.dateFrom != null )
      httpParams.push('datefrom=' + String(filter.dateFrom));
    if (filter.dateTo != null )
      httpParams.push('dateto=' + String(filter.dateTo));
    if (filter.activitytypes != null && filter.activitytypes.length > 0)
      httpParams.push('activitytypes=' + String(filter.activitytypes.join(';')));

    return this.http.get(
      '/api/v1/report/sales/presentation?' + httpParams.join('&'),
      {headers: {authorization: this.token}, responseType: 'blob'});
  }

  public downloadProjectReport(filter: {
    regions: string[];
    rsms: string[];
    bus: string[];
    customers: string[];
    dateFrom: string;
    dateTo: string;
    activitytypes: [];
    hideTopics: boolean;
  }): Observable<any> {
    const httpParams = [];
    if (filter.regions != null && filter.regions.length > 0)
      httpParams.push('regions=' + String(filter.regions.join(';')));
    if (filter.rsms != null && filter.rsms.length > 0)
      httpParams.push('rsms=' + String(filter.rsms.join(';')));
      httpParams.push('bus=' + String(filter.bus.join(';')));
    if (filter.customers != null && filter.customers.length > 0)
      httpParams.push('customers=' + String(filter.customers.join(';')));
    if (filter.dateFrom != null )
      httpParams.push('datefrom=' + String(filter.dateFrom));
    if (filter.dateTo != null )
      httpParams.push('dateto=' + String(filter.dateTo));
    if (filter.activitytypes != null && filter.activitytypes.length > 0)
      httpParams.push('activitytypes=' + String(filter.activitytypes.join(';')));

    return this.http.get(
      '/api/v1/report/project/report?' + httpParams.join('&'),
      {headers: {authorization: this.token}, responseType: 'blob'});
  }

  public downloadBusinesXlsx(extended = false) {
    return this.http.get(
      `/api/v1/report/business/${extended}`, {headers: {authorization: this.token}, responseType: 'blob'},
    );
  }
  public downloadOfferXlsx() {

    return this.http.get(
      '/api/v1/report/offerlist/xls', {headers: {authorization: this.token}, responseType: 'blob'},
    );
  }

  downloadSalesPresentationShort(filter: {
    regions: string[];
    rsms: string[];
    bus: string[];
    customers: string[];
    dateFrom: string;
    dateTo: string;
    activitytypes: [];
    hideTopics: boolean }): Observable<any> {

    const httpParams = [];
    if (filter.regions != null && filter.regions.length > 0)
      httpParams.push('regions=' + String(filter.regions.join(';')));
    if (filter.rsms != null && filter.rsms.length > 0)
      httpParams.push('rsms=' + String(filter.rsms.join(';')));
    if (filter.bus != null && filter.bus.length > 0)
      httpParams.push('bus=' + String(filter.bus.join(';')));
    if (filter.customers != null && filter.customers.length > 0)
      httpParams.push('customers=' + String(filter.customers.join(';')));
    if (filter.dateFrom != null )
      httpParams.push('datefrom=' + String(filter.dateFrom));
    if (filter.dateTo != null )
      httpParams.push('dateto=' + String(filter.dateTo));
    if (filter.activitytypes != null && filter.activitytypes.length > 0)
      httpParams.push('activitytypes=' + String(filter.activitytypes.join(';')));

    return this.http.get(
      '/api/v1/report/sales/presentation_short?' + httpParams.join('&'),
      {headers: {authorization: this.token}, responseType: 'blob'});
  }

  async downloadFile(file: FileModel) {
    // console.log(file.url, file);
    const data = await this.http.get('/api/v1' + file.url,
      {headers: {authorization: this.token}, responseType: 'blob'}).toPromise();
    saveAs(data, file.name);
  }

  /// //////////////////////////////////////////////////////////////////////////////////////////////////
  /// /// accdb

  public preloadBusiness(withContacts = false): Promise<BusinessModel[]> {
    return firstValueFrom(this.http.get('/api/v1/business/preload/' + withContacts,
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<BusinessModel>).body)))
  }

  public loadCores(): Promise<CoreModel[]> {
    return firstValueFrom(this.http.get('/api/v1/core',
      { headers: { authorization: this.token }})
      .pipe(map(response => (response as RequestArrayModel<CoreModel>).body)))
  }

  public saveCore(item: CoreModel): Promise<CoreModel> {
    return firstValueFrom(this.http.request(item._id == null ? 'post' : 'put', '/api/v1/core',
      { headers: { authorization: this.token }, body: new RequestObjectModel(item) })
      .pipe(map(response => (response as RequestObjectModel<CoreModel>).body)))
  }

  public loadSpoBoxes(filter: object = null): Promise<SpoBoxModel[]> {
    return firstValueFrom(this.http.get(`/api/v1/spo_box/${JSON.stringify(filter)}`,
      { headers: { authorization: this.token }})
      .pipe(map(response => (response as RequestArrayModel<SpoBoxModel>).body)))
  }

  public saveSpoBoxes(items: SpoBoxModel[]): Promise<SpoBoxModel[]> {
    // console.log('saveSpoBoxes', items)
    return firstValueFrom(this.http.request('post', '/api/v1/spo_box/save_items',
      { headers: { authorization: this.token }, body: new RequestObjectModel(items) })
      .pipe(map(response => (response as RequestArrayModel<SpoBoxModel>).body)))
  }

  public deleteSpoBoxes(items: SpoBoxModel[]): Promise<boolean> {
    return firstValueFrom(this.http.request('delete', '/api/v1/spo_box/delete_items',
      { headers: { authorization: this.token }, body: new RequestArrayModel(items) })
      .pipe(map(response => (response as RequestObjectModel<boolean>).body)))
  }

  public deleteSpoBoxesWithFilter(filter: object): Promise<boolean> {
    return firstValueFrom(this.http.request('delete', '/api/v1/spo_box/delete_items_filtered',
      { headers: { authorization: this.token }, body: new RequestObjectModel(JSON.stringify(filter)) })
      .pipe(map(response => (response as RequestObjectModel<boolean>).body)))
  }

  // /////////////// base product ///////////////////////////

  private mBaseProducts: BaseProductModel[] = null;
  public loadBaseProducts(): Promise<BaseProductModel[]> {
    return firstValueFrom(this.http.get('/api/v1/base_product',
      { headers: { authorization: this.token }})
      .pipe(map(response => (response as RequestArrayModel<BaseProductModel>).body)))
      .then(bps => {
        this.mBaseProducts = bps
        return bps;
      });
  }
  public get baseProducts(): BaseProductModel[] {
    return this.mBaseProducts;
  }

  public saveBaseProduct(item: BaseProductModel): Promise<BaseProductModel> {
    return firstValueFrom(this.http.request(item._id == null ? 'post' : 'put', '/api/v1/base_product',
      { headers: { authorization: this.token }, body: new RequestObjectModel(item) })
      .pipe(map(response => (response as RequestObjectModel<BaseProductModel>).body)))
  }

  public deleteBaseProduct(item: BaseProductModel) {
    return firstValueFrom(this.http.request('delete', '/api/v1/base_product',
      { headers: { authorization: this.token }, body: new RequestObjectModel(item) }))
  }

  public toggleDisableBaseProduct(code: string, value: boolean) {
    return firstValueFrom(this.http.request('put', `/api/v1/base_product/toggle_disable/${code}/${value}`,
      { headers: { authorization: this.token }})
      .pipe(map(response => (response as RequestObjectModel<boolean>).body)))
  }

  public async downloadBaseProductPDF(bp: BaseProductModel,
    type: DownloadType = DownloadType.document): Promise<boolean> {

    const data = await this.http.get(
      `/api/v1/base_product/pdf/${bp._id}/${type}`,
      {headers: {authorization: this.token}, responseType: 'blob'}).toPromise();
    const date = new Date().toISOString().match(/\d\d(\d\d)-(\d\d)-(\d\d)/);
    const version = bp.version < 10 ? `VS0${bp.version-1}` : `VS${bp.version-1}`;
    const name = bp.name; // bp.name.substr(0, 15);
    const ext = type === DownloadType.document || type === DownloadType.merged ? '.pdf' : ' - Documents.zip';
    saveAs(data, `${bp.code}BP - ${version} - ${bp.trial} - ${name} - ${date[1]+date[2]+date[3]}${ext}`);
    return true
  }

  public loadFinishedProductsOfBaseProduct(baseProduct: BaseProductModel): Promise<FinishedProductModel[]> {
    return firstValueFrom(this.http.get('/api/v1/base_product/finished_products/' + baseProduct._id,
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<FinishedProductModel>).body)))
  }

  // /////////////// finished product ///////////////////////////

  public getCalculatedCode(finishedProduct: FinishedProductModel): Promise<string> {
    return firstValueFrom(this.http.get('/api/v1/finished_product/code/' + finishedProduct._id,
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<string>).body)))
  }

  public loadFinishedProducts(): Promise<FinishedProductModel[]> {
    return firstValueFrom(this.http.get('/api/v1/finished_product',  {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<FinishedProductModel[]>).body)))
  }

  public deleteFinishedProduct(item: FinishedProductModel) {
    return firstValueFrom(this.http.request('delete', '/api/v1/finished_product',
      { headers: { authorization: this.token }, body: new RequestObjectModel(item) }))
  }

  public toggleDisableFinishedProduct(code: string, value: boolean) {
    return firstValueFrom(this.http.request('put', `/api/v1/finished_product/toggle_disable/${code}/${value}`,
      { headers: { authorization: this.token }})
      .pipe(map(response => (response as RequestObjectModel<boolean>).body)))
  }

  public filterFinishedProducts(filter: string): Promise<FinishedProductModel[]> {
    return firstValueFrom(this.http.get('/api/v1/finished_product/filter/' + filter,  {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<FinishedProductModel[]>).body)))
  }

  public loadFinishedProductById(id: string): Promise<FinishedProductModel> {
    return firstValueFrom(this.http.get('/api/v1/finished_product/id/' + id,  {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<FinishedProductModel>).body)))
  }

  public loadFinishedProductByCode(code: string): Promise<FinishedProductModel> {
    return firstValueFrom(this.http.get('/api/v1/finished_product/code/' + code,  {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<FinishedProductModel>).body)))
  }

  // public loadFinishedProductNames(): Promise<NameTable[]> {
  //   return firstValueFrom(this.http.get('/api/v1/finished_product/names',  {headers: {authorization: this.token}})
  //     .pipe(map(response => (response as RequestObjectModel<NameTable[]>).body)))
  // }

  public loadFinishedProductPriceMatrixData(): Promise<FinishedProductModel[]> {
    return firstValueFrom(this.http.get('/api/v1/finished_product/pricematrix',  {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<FinishedProductModel[]>).body)))
  }

  public getFinishedProductDiscountId(): Promise<FinishedProductModel> {
    return firstValueFrom(this.http.get('/api/v1/finished_product/discount_id',  {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<FinishedProductModel>).body)))
  }

  public saveFinishedProduct(item: FinishedProductModel): Promise<FinishedProductModel> {
    // console.log('in save finished product', item);
    return firstValueFrom(this.http.request(item._id == null ? 'post' : 'put', '/api/v1/finished_product',
      { headers: { authorization: this.token }, body: new RequestObjectModel(item) })
      .pipe(map(response => (response as RequestObjectModel<FinishedProductModel>).body)))
  }

  public async downloadFinishedProductPDF(fp: FinishedProductModel, bp: BaseProductModel,
    type: DownloadType = DownloadType.document): Promise<boolean> {

    const data = await firstValueFrom(this.http.get(
      `/api/v1/finished_product/pdf/${fp._id}/${type}`,
      {headers: {authorization: this.token}, responseType: 'blob'}))
    const date = new Date().toISOString().match(/\d\d(\d\d)-(\d\d)-(\d\d)/);
    const version = fp.version < 10 ? `VS0${fp.version-1}` : `VS${fp.version-1}`;
    const name = fp.calcName; // fp.calcName.substr(0, 20);
    const ext = type === DownloadType.document || type === DownloadType.merged ? '.pdf' : '.zip';
    saveAs(data, `${bp.code}FP - ${fp.calcCode} - ${version} - ${name} - ${date[1]+date[2]+date[3]}${ext}`);
    return true
  }

  // /////////////// price matrix ///////////////////////////

  public loadPriceMatrix(issuer: number): Promise<PriceMatrixModel[]> {
    return firstValueFrom(this.http.get(`/api/v1/pricematrix/${issuer}`,  {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<PriceMatrixModel[]>).body)))
  }

  public loadPrices(issuer: number, business: string, finishedProduct: string, salePrice: boolean = true): Promise<PriceMatrixModel> {
    // eslint-disable-next-line max-len
    // console.log('xxx load prices xxx')
    return this.http.get(`/api/v1/pricematrix/prices/${issuer}/${business}/${finishedProduct}/${salePrice}`,
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<PriceMatrixModel>).body))
      .toPromise();
  }

  public loadPrice(issuer: number, business: string, finishedProduct: string,
    priceDate: Date, currency: string, salePrice: boolean = true): Promise<PriceItem> {
    // eslint-disable-next-line max-len
    return firstValueFrom(this.http.get(`/api/v1/pricematrix/price/${issuer}/${business}/${finishedProduct}/${priceDate}/${currency}/${salePrice}`,
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<PriceItem>).body)))
  }

  public savePrice(issuer: number, business: string, finishedProduct: string,
    priceDate: Date, currency: string, accounting: number, priceType: number, value: number, minValue: number = null): Promise<PriceItem> {
    return firstValueFrom(this.http.request('put', '/api/v1/pricematrix/price',
      { headers: {authorization: this.token }, body: {
        issuer: issuer,
        business: business,
        finishedProduct: finishedProduct,
        priceDate: priceDate,
        currency: currency,
        accounting: accounting,
        type: priceType,
        value: value,
        minValue: minValue,
      }})
      .pipe(map(response => (response as RequestObjectModel<PriceItem>).body)))
  }

  public updatePriceMatrixFiles(issuer: number, business: string, finishedProduct: string,
    accounting: number, files: FileModel[]): Promise<any> {
    return firstValueFrom(this.http.request('put', `/api/v1/pricematrix/files/${issuer}/${business}/${finishedProduct}/${accounting}`,
      { headers: {authorization: this.token }, body: files })
      .pipe(map(response => (response as RequestObjectModel<PriceItem>).body)))
  }

  // public savePrice(item: FinishedProductModel): Promise<FinishedProductModel> {
  //   return this.http.request(item._id == null ? 'post' : 'put', '/api/v1/finished_product',
  //     { headers: { authorization: this.token }, body: new RequestObjectModel(item) })
  //     .pipe(map(response => (response as RequestObjectModel<FinishedProductModel>).body))
  //     .toPromise();
  // }


  // public addProductToCustomer(business: string, finishedProduct: string): Promise<FinishedProductModel> {
  //   return this.http.get('/api/v1/pricematrix/add/' + business + '/' + finishedProduct,
  //     {headers: {authorization: this.token}})
  //     .pipe(map(response => (response as RequestObjectModel<FinishedProductModel>).body))
  //     .toPromise();
  // }

  // public removeProductFromCustomer(business: string, finishedProduct: string): Promise<FinishedProductModel> {
  //   return this.http.get('/api/v1/pricematrix/remove/' + business + '/' + finishedProduct,
  //     {headers: {authorization: this.token}})
  //     .pipe(map(response => (response as RequestObjectModel<FinishedProductModel>).body))
  //     .toPromise();
  // }

  // public getCustomerFinishedProducts(business: string): Promise<FinishedProductModel[]> {
  //   return this.http.get('/api/v1/pricematrix/finishedProducts/' + business,
  //     {headers: {authorization: this.token}})
  //     .pipe(map(response => (response as RequestArrayModel<FinishedProductModel>).body))
  //     .toPromise();
  // }

  public savePriceMatrix(obj: {item: PriceMatrixModel, data: any}): Promise<PriceMatrixModel> {
    return firstValueFrom(this.http.request(obj.item._id == null ? 'post' : 'put', '/api/v1/pricematrix',
      { headers: { authorization: this.token }, body: new RequestObjectModel(obj) })
      .pipe(map(response => (response as RequestObjectModel<PriceMatrixModel>).body)))
  }

  public updateFinishedProductOfPriceMatrix(item: PriceMatrixModel): Promise<PriceMatrixModel> {
    return firstValueFrom(this.http.request('put', '/api/v1/pricematrix/finished-product',
      { headers: { authorization: this.token }, body: new RequestObjectModel(item) })
      .pipe(map(response => (response as RequestObjectModel<PriceMatrixModel>).body)))
  }

  public loadBaseProductPriceMatrix(baseProduct: string, issuer: number, customer: BusinessModel = null) {
    return firstValueFrom(this.http.get(`/api/v1/pricematrix/base-product-table/${baseProduct}/${issuer}/${customer}`,
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<any>).body)))
  }

  // /////////////// document ///////////////////////////

  public filterCompilations(filter: any = {}, fields: string = '', latestVersion = true): Promise<CompilationModel[]> {
    const fltr = JSON.stringify(filter);
    const ret = firstValueFrom(this.http.get(`/api/v1/compilation/filter/${fltr}/${fields}/${latestVersion}`, {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<CompilationModel[]>).body)))
    return ret;
  }

  public loadCompilation(id: string): Promise<CompilationModel> {
    if (!id) return new Promise<CompilationModel>(() => new CompilationModel());
    const ret = firstValueFrom(this.http.get('/api/v1/compilation/detail/' + id, {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<CompilationModel>).body)))
    return ret;
  }

  public loadCompilationByCode(code: string, version: number = null): Promise<CompilationModel> {
    return firstValueFrom(this.http.get(`/api/v1/compilation/detail/code/${code}/${version}`,
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<CompilationModel>).body)))
  }
  public loadCompilationsByCode(code: string): Promise<CompilationModel[]> {
    return firstValueFrom(this.http.get(`/api/v1/compilation/detail/versions/${code}`,
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<CompilationModel>).body)))
  }
  public loadCompilationsByCodes(codes: string[]): Promise<CompilationModel[]> {
    if (!codes?.length) return;
    return firstValueFrom(this.http.get(`/api/v1/compilation/detail/codes/${codes}`,
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<CompilationModel>).body)))
  }

  public findAssociatedOrderForPfspc(code: string): Promise<string[]> {
    return firstValueFrom(this.http.get('/api/v1/compilation/orderpfspc/' + code, {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<string>).body)))
  }
  public findAssociatedOc(code: string): Promise<string[]> {
    return firstValueFrom(this.http.get('/api/v1/compilation/assozoc/' + code, {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<string>).body)))
  }

  public getDocumentNumbers(types: number[], statuses: number[]): Promise<any[]> {
    const t = (types && types.join(',')) || null;
    const s = (statuses && statuses.join(',')) || null;
    return firstValueFrom(this.http.get(`/api/v1/compilation/docnums/${t}/${s}`, {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<any>).body)))
  }

  // public loadCustomerDocNums(recipient: string): Promise<{id: string, name: string}[]> {
  //   // console.log('loadCustomerDocNums', recipient);
  //   return this.http.get('/api/v1/compilation/customerdocnums/' + recipient, {headers: {authorization: this.token}})
  //     .pipe(map(response => (response as RequestArrayModel<{id: string, name: string}>).body))
  //     .toPromise();
  // }

  public saveCompilation(obj: CompilationModel): Promise<CompilationModel> {
    const copy = JSON.parse(JSON.stringify(obj));
    // tree cleanup
    if (copy.transportRun) {
      delete copy.transportRun.csCode;
      delete copy.transportRun.csDoc;
    }
    copy.lineItems.forEach((li: LineItemModel) => {
      if (li.pfspo) li.pfspo.forEach(spo => {
        if (spo.doc) delete spo.doc;
        if (spo.docLineItem) delete spo.docLineItem;
      })
      if (li.relatedOCs) delete li.relatedOCs;
      if (li['relatedOcs']) delete li['relatedOcs']; // TODO: get rid of..
    })
    typeof(copy.changelog) === 'function' && copy.changelog.forEach(cl => cl.user = mongoId(cl.user));
    typeof(copy.changelog) === 'function' && copy.shipmentLineItems?.forEach(li => {
      if (li.document) li.document = undefined; // mongoId(li.document);
    })
    // console.log('changelog copy', copy, obj);
    return firstValueFrom(this.http.request(copy._id == null ? 'post' : 'put', '/api/v1/compilation',
      { headers: { authorization: this.token }, body: new RequestObjectModel(copy) })
      .pipe(map(response => (response as RequestObjectModel<CompilationModel>).body)))
  }

  public updateField(codeOrId: string, field: string, obj: any, isObjectId = false): Promise<CompilationModel> {
    return firstValueFrom(this.http.request('put', `/api/v1/compilation/update/${codeOrId}/${field}/${isObjectId}`,
      { headers: { authorization: this.token }, body: new RequestObjectModel(obj) })
      .pipe(map(response => (response as RequestObjectModel<CompilationModel>).body)))
  }

  public synchronizeField(code: string, field: string, obj: any): Promise<CompilationModel> {
    return firstValueFrom(this.http.request('put', `/api/v1/compilation/sync/${code}/${field}`,
      { headers: { authorization: this.token }, body: new RequestObjectModel(obj) })
      .pipe(map(response => (response as RequestObjectModel<CompilationModel>).body)))
  }

  public fixErrors(restore = false): Promise<boolean> {
    return firstValueFrom(this.http.get('/api/v1/compilation/fix_errors/' + restore,
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<boolean>).body)))
  }

  public setInvoiceDownloaded(id: string): Promise<boolean>/* success */ {
    return firstValueFrom(this.http.request('put', '/api/v1/compilation/downloaded/' + id,{ headers: { authorization: this.token }})
      .pipe(map(response => (response as RequestObjectModel<boolean>).body)))
  }

  public cancelCompilation(code: string, text: string, changelog: ChangelogEntry): Promise<boolean> {
    return firstValueFrom(this.http.request('put', `/api/v1/compilation/cancel/${code}/${text}`,
      { headers: { authorization: this.token }, body: new RequestObjectModel(changelog) })
      .pipe(map(response => (response as RequestObjectModel<boolean>).body)))
  }

  public deleteCompilation(item: CompilationModel) {
    return firstValueFrom(this.http.request('delete', '/api/v1/compilation',
      { headers: { authorization: this.token }, body: new RequestObjectModel(item) }))
  }

  public getAllVersionData(preload = false, latest = false, fields: string[] = null, doctype = null, from = null, to = null): Promise<AbstractItemType[]> {
    return firstValueFrom(this.http.get(`/api/v1/compilation/versioninfo/${preload}/${latest}/${fields}/${doctype}/${from}/${to}`,
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<AbstractItemType>).body)))
  }

  // eslint-disable-next-line max-len
  public getDocumentFile(api: string, config: DownloadConfig): Observable<Blob> {
    return this.http.post(api, new RequestObjectModel(config),
      {headers: {authorization: this.token}, responseType: 'blob'})
  }

  public getDocumentReport(type: string, obj: any, pdf: boolean = true): Observable<any> {
    // console.log('document report')
    return this.http.post('/api/v1/document_report/' + (pdf ? 'pdf' : 'xls') + '/' + type,
      new RequestObjectModel(obj), {headers: {authorization: this.token}, responseType: 'json'})
  }

  public getDocumentReportAsync(type: string, obj: any, pdf: boolean = true): Promise<any> {
    // console.log('document report async')
    return new Promise(resolve => this.getDocumentReport(type, obj, pdf).subscribe(data => resolve(data)));
  }

  public getDocumentReport2(type: string, obj: any, pdf: boolean = true): Observable<any> {
    console.log('document report')
    return this.http.post('/api/v1/document_report/chart',
      new RequestObjectModel(obj), {headers: {authorization: this.token}, responseType: 'json'})
  }

  public getDocumentReportAsync2(type: string, obj: any, pdf: boolean = true): Promise<any> {
    return new Promise(resolve => this.getDocumentReport2(type, obj, pdf).subscribe(data => resolve(data)));
  }

  public downloadXlsxFromTable(table: any): Observable<any> {
    return this.http.post('/api/v1/documentReport/xls',
      new RequestObjectModel(table), {headers: {authorization: this.token}, responseType: 'json'})
  }

  // public sendReports(): Observable<any> {
  //   console.log('send reports...');
  //   return this.http.post('/api/v1/documentReport/reportsmail',
  //     new RequestObjectModel({ sendReports: true }), {headers: {authorization: this.token}, responseType: 'json'})
  // }

  public async sendReports(): Promise<any> {
    console.log('send reports...');
    return firstValueFrom(this.http.get('/api/v1/document_report/reportsmail',
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<any>).body)))
  }


  /////////

  public async getOcOptions(): Promise<{id: string, name: string}[]> {
    return firstValueFrom(this.http.get('/api/v1/compilation/ocoptions',
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<{id: string, name: string}>).body)))
  }
  public async getCsOptions(): Promise<any[]> {
    return firstValueFrom(this.http.get('/api/v1/compilation/csoptions',
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<any>).body)))
  }

  public getCompilationData(key: string = 'none'): Promise<CompilationDataModel | any> {
    return firstValueFrom(this.http.get('/api/v1/compilation/get_data/' + key,
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<CompilationDataModel>).body)))
  }

  public saveCompilationData(key: string, value: string | any, add = true /* add or remove */): Promise<CompilationDataModel> {
    // if value is string add/remove value; if value is any object replace whole store[key]
    return firstValueFrom(this.http.request('put', `/api/v1/compilation/put_data/${key}/${add}`,
      { headers: { authorization: this.token }, body: new RequestObjectModel(value)})
      .pipe(map(response => (response as RequestObjectModel<CompilationDataModel>).body)))
  }

  public getCombinedShipments(): Promise<CompilationModel[]> {
    return firstValueFrom(this.http.get('/api/v1/compilation/cs',
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<CompilationModel>).body)))
  }
  public findCombinedShipment(spoCode: string): Promise<CompilationModel> {
    return firstValueFrom(this.http.get(`/api/v1/compilation/find_cs/${spoCode}`,
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestObjectModel<CompilationModel>).body)))
  }
  public removeFromCombinedShipment(spo: CompilationModel, cs: CompilationModel): Promise<CompilationModel> {
    return firstValueFrom(this.http.request('put', `/api/v1/compilation/remove_from_cs/${spo.code}/${cs._id}`,
      { headers: { authorization: this.token }})
      .pipe(map(response => (response as RequestObjectModel<CompilationModel>).body)))
  }
  public getAssignedCombinedShipment(spo: CompilationModel): Promise<CompilationModel> {
    return firstValueFrom(this.http.request('put', `/api/v1/compilation/get_assigned_cs/${spo.code}`,
      { headers: { authorization: this.token }})
      .pipe(map(response => (response as RequestObjectModel<CompilationModel>).body)))
  }

  public getCustomerReferenceData(): Promise<{code: string, customerReference: string, customerDocumentNumber: string}[]> {
    return this.http.get('/api/v1/compilation/get_reference_data',
      {headers: {authorization: this.token}})
      .pipe(map(response => (response as RequestArrayModel<{code: string, customerReference: string, customerDocumentNumber: string}>).body))
      .toPromise();
  }
  
  // public getSpoData(): Promise<any[]> {
  //   return new Promise((resolve) => {
  //     const req = this.http.get('/api/v1/compilation/get_spo_data',
  //       {headers: {authorization: this.token}})
  //       .subscribe(s => {
  //         req.unsubscribe();
  //         resolve(s as any[]);
  //       })
  //   })
  // }

  public getSpoData(calcCode: string = null) {
    return firstValueFrom(this.http.get(`/api/v1/compilation/get_spo_data/${calcCode}`,
      { headers: { authorization: this.token } })
      .pipe(map(response => (response as RequestArrayModel<any>).body)))
  }

  public getCachedSpoOcMap(spoCode: string, spoPos: number) {
    return firstValueFrom(this.http.get(`/api/v1/compilation/get_spo_oc_map/${spoCode}/${spoPos}`,
      { headers: { authorization: this.token } })
      .pipe(map(response => (response as RequestArrayModel<any>).body)))
  }

  public getRelatedOcs(code: string) {
    // console.log('get related ocs', code);
    try {
      return firstValueFrom(this.http.post(`/api/v1/compilation/get_related_ocs/${code}`,
        { headers: { authorization: this.token } })
        .pipe(map(response => (response as RequestObjectModel<any>).body)))
    } catch (e) {
      // console.log('get related ocs', e, code);
    }
  }

  public getOffers() {
    return firstValueFrom(this.http.get('/api/v1/compilation/offers',
      { headers: { authorization: this.token } })
      .pipe(map(response => (response as RequestObjectModel<any>).body)))
  }

  public findUpstreamDocumentByType(code: string, type: number) {
    return firstValueFrom(this.http.get(`/api/v1/compilation/find_doc_by_type/${code}/${type}`,
      { headers: { authorization: this.token } })
      .pipe(map(response => (response as RequestObjectModel<any>).body)))
  }

  public findDownstreamDocumentByType(code: string, type: number) {
    return firstValueFrom(this.http.get(`/api/v1/compilation/find_ds_doc_by_type/${code}/${type}`,
      { headers: { authorization: this.token } })
      .pipe(map(response => (response as RequestObjectModel<any>).body)))
  }

  public getPalletReferences() {
    return firstValueFrom(this.http.get('/api/v1/compilation/get_pallet_references',
      { headers: { authorization: this.token } })
      .pipe(map(response => (response as RequestArrayModel<IPalletRef>).body)))
  }

  public increaseCounter(name: string) {
    return firstValueFrom(this.http.get(`/api/v1/compilation/counter_increase/${name}`,
      { headers: { authorization: this.token } })
      .pipe(map(response => (response as RequestObjectModel<any>).body)))
  }

  public getCounter(name: string) {
    return firstValueFrom(this.http.get(`/api/v1/compilation/counter_get/${name}`,
      { headers: { authorization: this.token } })
      .pipe(map(response => (response as RequestObjectModel<any>).body)))
  }

  // public updateAssociatedPfspos(code: string) {
  //   return this.http.request('post', '/api/v1/compilation/updateAssociatedPfspos',
  //     { headers: { authorization: this.token }, body: new RequestObjectModel({ code: code }) })
  //     .pipe(map(response => (response as RequestObjectModel<any>).body))
  //     .toPromise();
  // }

  // public getDocumentTree(code: string) {
  //   return firstValueFrom(this.http.get(`/api/v1/compilation/get_document_tree/${code}`,
  //     { headers: { authorization: this.token } })
  //     .pipe(map(response => (response as RequestObjectModel<any>).body)))
  // }

  private documentTreeSubscription: Subscription;
  private getDocumentTreeObs(code: string): Observable<any> {
    return this.http.get(`/api/v1/compilation/get_document_tree/${code}`,
      { headers: { authorization: this.token } })
      .pipe(map(response => (response as RequestObjectModel<any>).body))
  }
  public getDocumentTree(code: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.documentTreeSubscription = this.getDocumentTreeObs(code).subscribe({
        next: data => { resolve(data); console.log('change document, getDocumentTree data received') },
        error: err => { reject(err); console.log('change document, getDocumentTree data error') },
        complete: () => { console.log('change document, getDocumentTree done'); this.documentTreeSubscription = undefined }
      })
    })
  }
  public cancelGetDocumentTree(): void {
    if (this.documentTreeSubscription) {
      console.log('change document, cancelGetDocumentTree', this.documentTreeSubscription);
      this.documentTreeSubscription.unsubscribe();
    }
  }

  public getCache(cacheType: CacheType, options = {}) {
    const opts = encodeURIComponent(JSON.stringify(options));
    // console.log('getCache', opts);
    return firstValueFrom(this.http.get(`/api/v1/compilation/get_cache/${cacheType}/${opts}`,
      { headers: { authorization: this.token } })
      .pipe(map(response => (response as RequestObjectModel<any>).body)))
  }

  public refreshCache() {
    return firstValueFrom(this.http.get(`/api/v1/compilation/refresh_cache`,
      { headers: { authorization: this.token } })
      .pipe(map(response => (response as RequestObjectModel<any>).body)))
  }

  public refreshTreeCache() {
    return firstValueFrom(this.http.get(`/api/v1/compilation/refresh_tree_cache`,
      { headers: { authorization: this.token } })
      .pipe(map(response => (response as RequestObjectModel<any>).body)))
  }

  public getPositionMap() {
    return firstValueFrom(this.http.get(`/api/v1/compilation/position_map`,
      { headers: { authorization: this.token } })
      .pipe(map(response => (response as RequestObjectModel<any>).body)))
  }

  public getSpoOfTC(tc: string) {
    return firstValueFrom(this.http.get(`/api/v1/compilation/tc_spo_map/${tc}`,
      { headers: { authorization: this.token } })
      .pipe(map(response => (response as RequestObjectModel<any>).body)))
  }

  public sendmail(user: string, from: string, subject: string, html: string): Observable<any> {
    const maildata = { user, from, subject, html };
    return this.http.post('/api/v1/compilation/sendmail',
      new RequestObjectModel(maildata), {headers: {authorization: this.token}, responseType: 'json'})
  }

  ////////////////////////////////////////////////////////////////////////////////
  //
  // Warehousing
  //
  
  public loadWarehousingEntries(filter: object = {}): Promise<WarehousingModel[]> {
    return firstValueFrom(this.http.get(`/api/v1/warehousing?filter=${encodeURIComponent(JSON.stringify(filter))}`,
      { headers: { authorization: this.token }})
      .pipe(map(response => (response as RequestArrayModel<WarehousingModel>).body)))
  }

  public loadWarehousingEntry(id: string): Promise<WarehousingModel> {
    return firstValueFrom(this.http.get(`/api/v1/warehousing/${id}`,
      { headers: { authorization: this.token }})
      .pipe(map(response => (response as RequestObjectModel<WarehousingModel>).body)))
  }

  public saveWarehousingEntry(item: WarehousingModel): Promise<WarehousingModel> {
    return firstValueFrom(this.http.request(item._id == null ? 'post' : 'put', '/api/v1/warehousing',
      { headers: { authorization: this.token }, body: new RequestObjectModel(item) })
      .pipe(map(response => (response as RequestObjectModel<WarehousingModel>).body)))
  }

  public markWarehousingEntryAsRead(item: WarehousingModel, user: UserModel) {
    return firstValueFrom(this.http.get(`/api/v1/warehousing/markAsRead/${mongoId(item)}/${mongoId(user)}`,
      { headers: { authorization: this.token }})
      .pipe(map(response => (response as RequestArrayModel<boolean>).body)))
  }
 

  //////////////////////////////////////////////////////////////////////////////
  //
  //  Developement helpers
  //

  public queryRunner(): Promise<any> {
    return this.http.request('get', '/api/v1/devhelper/queryRunner',
      { headers: { authorization: this.token }})
      .pipe(map(response => (response as RequestObjectModel<any>).body))
      .toPromise();
  }

  public getCustomerDocRefs(customerIds: string[]) {
    return this.http.request('post', '/api/v1/compilation/get_customer_doc_refs',
      { headers: { authorization: this.token }, body: new RequestArrayModel<string>(customerIds) })
      .pipe(map(response => (response as RequestArrayModel<any>).body))
      .toPromise();
  }
  public getCustomerFinishedProducts(customerIds: string[]) {
    return this.http.request('post', '/api/v1/compilation/get_customer_finished_products',
      { headers: { authorization: this.token }, body: new RequestArrayModel<string>(customerIds) })
      .pipe(map(response => (response as RequestArrayModel<any>).body))
      .toPromise();
  }

  public sanitizeField(obj: { doc: CompilationModel, field: string, type: string }) {
    console.log('sanitize field');
    return this.http.request('post', '/api/v1/devhelper/sanitizeField',
      { headers: { authorization: this.token }, body: new RequestObjectModel<any>(obj) })
      .pipe(map(response => (response as RequestArrayModel<any>).body))
      .toPromise();
  }

  public fiscalYearUpdate(obj: { doc: CompilationModel, field: string, type: string }) {
    console.log('sanitize field');
    return this.http.request('post', '/api/v1/devhelper/fiscalYearUpdate',
      { headers: { authorization: this.token }, body: new RequestObjectModel<any>(obj) })
      .pipe(map(response => (response as RequestArrayModel<any>).body))
      .toPromise();
  }
  
  public syncFiscalYearINDN(): Promise<any> {
    return firstValueFrom(this.http.request('get', '/api/v1/devhelper/syncFiscalYearINDN',
      { headers: { authorization: this.token }})
      .pipe(map(response => (response as RequestObjectModel<any>).body)))
  }

  public migrateCartonBoxPacking(): Promise<any> {
    return firstValueFrom(this.http.request('get', '/api/v1/devhelper/migrateCartonBoxPacking',
      { headers: { authorization: this.token }})
      .pipe(map(response => (response as RequestObjectModel<any>).body)))
  }



  ////////////////////////////////////////////////////////////////////////////
  // public sendBugReport(data: any) {
  //   this.http.post('http://feedback.nrk.rebus.link/bugreport/bugreport.php', data);
  //   (console as any).defaultLog('sending bug report');
  // }

  public restartScheduler(): Promise<any> {
    return firstValueFrom(this.http.request('get', '/api/v1/util/restartScheduler',
      { headers: { authorization: this.token }})
      .pipe(map(response => (response as RequestObjectModel<any>).body)))
  }

  public sendBugReport(data: any): Promise<any> {
    // console.log('sendBugReport', data);
    return this.http.request('post', '/api/v1/util/bugReport',
      { headers: { authorization: this.token }, body: new RequestObjectModel(data) })
      .pipe(map(response => (response as RequestObjectModel<any>).body))
      .toPromise();
  }

  public debugConsoleLog(subject: string): Promise<any> {
    const log = (console as any).errors;

    log.forEach(el => {
      el.data = el.data.map(m => {
        if (m === null || m === undefined) return m;
        switch (typeof m) {
          case 'object':
            if (el.type === 'err') {
              if (m.message && m.stack) return { message: m.message, stack: m.stack }
            }
            return 'object';
          case 'number':
          case 'string':
            return m;
          default:
            return Array.isArray(m) ? 'array' : 'object...'
        }
      })
    });

    const obj = new DebugModel();
    obj.type = EDebugType.console;
    obj.subject = subject;
    obj.version = SampleLayoutComponent.version;
    obj.data = log.map(m => m.type === 'log' ? `[L][${m.time}] ${m.data.join(', ')}` : {
        header: `[E][${m.time}]`,
        message : m.data[1].message.split('\n'),
        stack: m.data[1].stack.split('\n'),
      }
    ).slice(log.length-200, log.length);


    // console.log('debugLog', data, JSON.stringify(data));
    return firstValueFrom(this.http.request('post', '/api/v1/devhelper/debug',
      { headers: { authorization: this.token }, body: new RequestObjectModel(obj) })
      .pipe(map(response => (response as RequestObjectModel<any>).body)))  
  }


  public debugLog(subject: string, data: any = undefined, type = EDebugType.log): Promise<any> {
    const obj = new DebugModel();
    obj.type = type;
    obj.subject = subject;
    obj.version = SampleLayoutComponent.version;
    obj.data = data;

    console.log(`---- debugLog ----
      type: ${type}
      subject: ${subject}
      time: ${new Date().toISOString()}
      data:`, data)

    return firstValueFrom(this.http.request('post', '/api/v1/devhelper/debug',
      { headers: { authorization: this.token }, body: new RequestObjectModel(obj) })
      .pipe(map(response => (response as RequestObjectModel<any>).body)))
  }

  public getDebug(filter: any): Promise<DebugModel[]> {
    return firstValueFrom(this.http.get(`/api/v1/devhelper/debug/${JSON.stringify(filter)}`,
      { headers: { authorization: this.token }})
      .pipe(map(response => (response as RequestArrayModel<DebugModel>).body)))
  }

  ////////
  // mongo dump service 
  getFolders(root: string): Promise<string[]> {
    // return this.http.get<string[]>(`${this.apiUrl}`);
    return firstValueFrom(this.http.get(`/api/v1/devhelper/dumps?root=${encodeURIComponent(root)}`,
      { headers: { authorization: this.token }})
      .pipe(map(response => (response as RequestArrayModel<string>).body)))
  }

  getFiles(path: string): Promise<string[]> {
    // return this.http.get<string[]>(`${this.apiUrl}/${folder}`);
    return firstValueFrom(this.http.get(`/api/v1/devhelper/folder?path=${encodeURIComponent(path)}`,
      { headers: { authorization: this.token }})
      .pipe(map(response => (response as RequestArrayModel<string>).body)))
  }

  searchInFile(data: { folder: string, file: string, param: string, value: string }): Promise<any[]> {
    // return this.http.post<any>(`${this.apiUrl}/search`, data);
    return firstValueFrom(this.http.request('post', '/api/v1/devhelper/dumps/search',
      { headers: { authorization: this.token }, body: new RequestObjectModel(data) })
      .pipe(map(response => (response as RequestArrayModel<any>).body)))  

  }


  // public sendBugReport(log: any): Observable<any> {
  //   return this.http.post('/api/v1/bugReport/',
  //     new RequestObjectModel(log), {headers: {authorization: this.token}, responseType: 'json'})
  // }
  // public async sendBugReport(data: string) {
  //   const response = await this.http.request('post', 'https://feedback.nrk.rebus.link/bugreport/bugreport.php',
  //     { headers: { contentType: 'text/plain' }, body: data })
  //     .pipe(map(response => (response as RequestObjectModel<any>).body))
  //     .toPromise();
    
  //   console.log('response', response);
  // }


  
}
