<!-- plain -->
<!-- dismiss -->

<nb-card *ngIf="dialogType === dialog.plain || dialogType === dialog.dismiss">
  <nb-card-header>{{header ? header : 'WARNING' }}</nb-card-header>
  <nb-card-body>
    {{message ? message : 'You have unsaved changes!'}}
  </nb-card-body>
  <nb-card-footer *ngIf="dialogType === 20">
    <button class="btn btn-success btn-sm"  (click)="save()">Save</button>
    <button class="btn btn-danger btn-sm"  (click)="dismiss()">Dismiss</button>
    <button class="btn btn-default btn-sm"  (click)="cancel()">Cancel</button>
  </nb-card-footer>
  <nb-card-footer *ngIf="dialogType !== 20">
    <button class="btn btn-default btn-sm" (click)="cancel()">{{okText}}</button>
  </nb-card-footer>
</nb-card>

<!--==================================================================================================================================================-->
<!-- input -->
<nb-card *ngIf="dialogType === dialog.input">
  <nb-card-header>{{header ? header: 'Input'}}</nb-card-header>
  <nb-card-body>
    {{message ? message : 'Text:'}}
    <input type="text" style="width:20em" [(ngModel)]="responseText" />
  </nb-card-body>
  <nb-card-footer>
    <button class="btn btn-success btn-sm"  (click)="ok()">Ok</button>
  </nb-card-footer>
</nb-card>

<!--==================================================================================================================================================-->
<!-- okCancel -->
<!-- okCancelResponse -->
<!-- revise -->
<!-- updateDelivery -->

<nb-card *ngIf="dialogType === 2 || dialogType === 3 || dialogType === 7 || dialogType === 21">
  <nb-card-header>WARNING</nb-card-header>
  <nb-card-body>
    <div style="text-align:left;display:inline-block" *ngIf="relatedDocuments?.length">
      Cancel document(s):<br>
      <!-- <input type="checkbox" [ngModel]="true" [attr.disabled]="true" />{{compilation.nameOverride || compilation.code}}<br> -->
      <ng-container *ngFor="let doc of relatedDocuments; index as idx">
        <input type="checkbox" [(ngModel)]="cancelledDownstreamDocuments[idx]" [attr.disabled]="compilation && idx == 0 ? true : null" />
          {{doc.text}}<br>
      </ng-container>
      <ng-container *ngIf="removedSpos?.length">
        <br><br>
        (PF)SPOs will be removed from OC:
        <div class="ml-2" *ngFor="let spo of removedSpos">- {{spo}}</div>
      </ng-container>
      <br><br>
    </div>
    <label>{{message}}</label>
    <table class="nrk-table delivery-terms mx-auto" *ngIf="dialogType === 21">
      <tr>
        <th>from:</th>
        <td>
          {{compilation?.deliveryConditions?.incoterms}}<br>
          {{compilation?.deliveryConditions?.place}}<br>
          {{compilation?.deliveryConditions?.country}}</td>
      </tr>
      <tr>
        <th>to:</th>
        <td>
          {{deliveryAndTerms?.incoterms?.value}}<br>
          {{deliveryAndTerms?.place?.value}}<br>
          {{deliveryAndTerms?.country?.value}}</td>
      </tr>
    </table>
    <ngx-doc-form-texfield *ngIf="dialogType !== 7 && dialogType !==21" class="fullwidth" [(value)]="responseText"></ngx-doc-form-texfield>
  </nb-card-body>
  <nb-card-footer>
    <button class="btn btn-success btn-sm"  (click)="save()">{{okText}}</button>
    <button class="btn btn-danger btn-sm"  (click)="cancel()">{{cancelText}}</button>
  </nb-card-footer>
</nb-card>

<!--==================================================================================================================================================-->
<!-- spoSelection -->
<!-- nrkaOrSelect -->
<!-- pfcsponrkg -->

<nb-card *ngIf="dialogType === dialog.spoSelection || dialogType === dialog.nrkaOrSelect || dialogType === dialog.pfcsponrkg" >
  <!-- <nb-card-header>Selection required</nb-card-header> -->
  <nb-card-body>
    <ng-container *ngIf="dialogType === dialog.nrkaOrSelect">
      <label>Select parent NRKG order</label>
      <ngx-doc-form-filter-select class="fullwidth" [(value)]="orderCode" [options]="orderOptions"></ngx-doc-form-filter-select>
      <br>
      <br>
    </ng-container>
    <ng-container *ngIf="dialogType === dialog.spoSelection">
      <label>Select recipient</label>
      <ngx-doc-form-filter-select class="fullwidth" [(value)]="responseText" [options]="businessOptions"></ngx-doc-form-filter-select>
      <br>
      <br>
    </ng-container>
    <ng-container *ngIf="showDeliveryConditions">
      <label>Delivery conditions import</label>
      <ul>
        <li><input type="radio" [value]="1" name="deliveryConditions" [(ngModel)]="deliveryConditions"/><span (click)="deliveryConditions=1">Inherit from recipient</span></li>
        <li><input type="radio" [value]="2" name="deliveryConditions" [(ngModel)]="deliveryConditions"/><span (click)="deliveryConditions=2">Inherit from parent document</span></li>
        <li><input type="radio" [value]="3" name="deliveryConditions" [(ngModel)]="deliveryConditions"/><span (click)="deliveryConditions=3">Leave empty</span></li>
      </ul>
      <br>
    </ng-container>
    <label>Terms of payment import</label>
    <ul>
      <li><input type="radio" [value]="1" name="termsOfPayment" [(ngModel)]="termsOfPayment"/><span (click)="termsOfPayment=1">Inherit from recipient</span></li>
      <li><input type="radio" [value]="2" name="termsOfPayment" [(ngModel)]="termsOfPayment"/><span (click)="termsOfPayment=2">Inherit from parent document</span></li>
      <li><input type="radio" [value]="3" name="termsOfPayment" [(ngModel)]="termsOfPayment"/><span (click)="termsOfPayment=3">Leave empty</span></li>
      <li><input type="radio" [value]="4" name="termsOfPayment" [(ngModel)]="termsOfPayment"/><span (click)="termsOfPayment=4">NO COMMERCIAL VALUE...</span></li>
    </ul>
    <br>
    <label>Global VAT</label>
    <ul class="vat">
      <li *ngFor="let option of vatOptions"><input type="radio" [value]="option.id" name="vat" [(ngModel)]="vat"/><span (click)="vat=option.id">{{option.name}} %</span></li>
    </ul>
    <ng-container *ngIf="dialogType === dialog.nrkaOrSelect || dialogType === dialog.pfcsponrkg">
      <br>
      <label>Update prices</label>
      <ul class="vat">
        <li *ngFor="let option of priceOptions"><input type="radio" [value]="option.id" name="price" [(ngModel)]="inheritPrice"/><span (click)="inheritPrice=option.id">{{option.name}}</span></li>
      </ul>
    </ng-container>
    <br>
    <ng-container *ngIf="dialogType === 4 && compilation.type === 3">
      <ul>
        <li><input type="checkbox" [(ngModel)]="attachToOC"/><span>Associate line items with parent OC</span></li>
      </ul>
    </ng-container>
  </nb-card-body>
  <nb-card-footer>
    <button class="btn btn-success btn-sm"  (click)="ok()">Ok</button>
  </nb-card-footer>
</nb-card>

<!--==================================================================================================================================================-->
<!-- pdfSelection -->

<nb-card *ngIf="dialogType === 5">
  <nb-card-header>SELECT</nb-card-header>
  <nb-card-body>
    <div *ngIf="parentOc">
      {{getOcTitle()}} complete: {{parentOc.completelyInvoiced ? '✓' : ''}}<br>
      <button class="btn btn-info btn-sm"  (click)="setParentOcCompleted()">Set parent {{getOcTitle()}} completed</button>
    </div>
    <!-- <div *ngIf="associatedSpos?.length" style="margin-left: 2em">
      <ngx-doc-form-checkbox label="Set associated (PF)SPOs completed?" [(value)]="setSposCompleted"></ngx-doc-form-checkbox>
    </div> -->
    <!-- <div *ngIf="compilation && !['IN', 'PFIN'].includes(typeData.get(compilation.type).code)">
      Issue date: {{compilation.issueDate | date : 'yy-MM-dd' }}<br>
      <button class="btn btn-info btn-sm"  (click)="updateIssueDate()">Update issue date</button>
    </div> -->
  </nb-card-body>
  <nb-card-footer>
    <label>Select which type of PDF to generate</label>
    <br>
    <ng-container *ngIf="!attachOnly && ['IN','PFIN'].includes(typeData.get(compilation?.type)?.code)">
      <button class="btn btn-primary btn-sm"  (click)="pdf(downloadType.document)">Save IN</button>
      <button class="btn btn-primary btn-sm"  (click)="pdf(downloadType.document, true)">Save IN + DN</button>
      <button class="btn btn-primary btn-sm"  (click)="pdf(downloadType.document, true, true)">Save IN + DN + TC</button>
    </ng-container>
    <ng-container>
      <button *ngIf="!attachOnly && !['IN','PFIN'].includes(typeData.get(compilation?.type)?.code)" class="btn btn-primary btn-sm"  (click)="pdf(downloadType.document)">Save PDF</button>
    </ng-container>
    <!-- <button *ngIf="!attachOnly" class="btn btn-primary btn-sm"  (click)="pdf(downloadType.document)">Save PDF</button> -->
    <!-- <button *ngIf="!attachOnly" class="btn btn-primary btn-sm"  (click)="evalState(); pdf(downloadType.merged)">Merge PDF with attachments</button> -->
    <button *ngIf="!attachOnly" class="btn btn-primary btn-sm"  (click)="pdf(downloadType.zipMerged)">ZIP (document & attachments)</button>
    <button class="btn btn-primary btn-sm"  (click)="pdf(downloadType.zipAttachments)">ZIP (attachments only)</button>
    <button *ngIf="!attachOnly" class="btn btn-primary btn-sm"  (click)="pdf(downloadType.pdfAndZip)">PDF & zip</button>
    <button class="btn btn-default btn-sm"  (click)="cancel()">Exit without doc</button>
  </nb-card-footer>
</nb-card>

<!--==================================================================================================================================================-->
<!-- metrics -->

<div *ngIf="dialogType === dialog.metrics" class="metrics">
  <ngx-line-item-metrics 
    #metrics 
    [compilation]="compilation" 
    [lineItem]="lineItem" 
    [disabled]="disabled" 
    (recalc)="recalc"
    (close)="ok()"
  ></ngx-line-item-metrics>
</div>

<!--==================================================================================================================================================-->
<!-- updateDeliveryAndTerms -->

<nb-card *ngIf="dialogType === 10">
  <nb-card-header>Selection required</nb-card-header>
  <nb-card-body>
    <div style="display:inline-block;text-align:left">
      <label>
        <input type="checkbox" [checked]="updateDelivery" (change)="updateDelivery = !updateDelivery" />
        Update delivery conditions
      </label>
      <table class="nrk-table delivery-terms">
        <tr>
          <th>from:</th>
          <td>
            {{compilation?.deliveryConditions?.incoterms}}<br>
            {{compilation?.deliveryConditions?.place}}<br>
            {{compilation?.deliveryConditions?.country}}</td>
        </tr>
        <tr>
          <th>to:</th>
          <td>
            {{deliveryAndTerms?.incoterms?.value}}<br>
            {{deliveryAndTerms?.place?.value}}<br>
            {{deliveryAndTerms?.country?.value}}</td>
        </tr>
      </table>
      <br>
      <label>
        <input type="checkbox" [checked]="updateTerms" (change)="updateTerms = !updateTerms" />
        Update terms of payment
      </label>
      <table class="nrk-table delivery-terms">
        <tr>
          <th>from:</th>
          <td>{{compilation?.paymentPlan?.text}}<br>
            {{compilation?.paymentPlan?.termsOfPayment}}</td>
        </tr>
        <tr>
          <th>to:</th>
          <td>{{deliveryAndTerms?.paymentText?.value}}<br>
            {{deliveryAndTerms?.terms?.value}}</td>
        </tr>
      </table>
      <br>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button #okBtn class="btn btn-success btn-sm"  (click)="update()">Update</button>
    <button class="btn btn-danger btn-sm"  (click)="cancel()">Cancel</button>
  </nb-card-footer>
</nb-card>

<!--==================================================================================================================================================-->
<!-- updateDeliveryAndTerms -->

<nb-card *ngIf="dialogType === 11" style="width:40rem;height:52rem">
  <nb-card-header>Selection Businesses</nb-card-header>
  <nb-card-body style="text-align:left">
    <label>Select Customers:</label>
    <ngx-form-array-field title="Customers" [options]="businessOptions" [(value)]="customers" [allBusinessOption]="false" [placeholder]="'select customer'"></ngx-form-array-field>
    <br>

    <label>Select Suppliers:</label>
    <br>
    <ngx-form-array-field title="Suppliers" [options]="businessOptions" [(value)]="suppliers" [allBusinessOption]="false" [placeholder]="'select supplier'"></ngx-form-array-field>
  </nb-card-body>
  <nb-card-footer>
    <button class="btn btn-success btn-sm"  (click)="clone()">Clone</button>
    <button class="btn btn-danger btn-sm"  (click)="cancel()">Cancel</button>
  </nb-card-footer>
</nb-card>

<!--==================================================================================================================================================-->
<!-- pfspoSelector -->

<div *ngIf="dialogType === 12" class="metrics">
  <ngx-line-item-pfspo-selector
    [compilation]="compilation" 
    [lineItem]="lineItem" 
    [disabled]="disabled" 
    (recalc)="recalc"
    (close)="response=$event;ok()"
  ></ngx-line-item-pfspo-selector>
</div>

<!--==================================================================================================================================================-->
<!-- pfspoAvailability -->

<div *ngIf="dialogType === 23" class="window pfspo-availability">
  <ngx-pfspo-availability
  [compilation]="compilation"
  [lineItem]="lineItem"
  [ocPosition]="position"
  [pfspo]="pfspo"
  [disabled]="disabled"
  [popup]="true"
  (close)="$event ? save() : cancel()"
  ></ngx-pfspo-availability>
</div>

<!--==================================================================================================================================================-->
<!-- pfspoText -->

<nb-card *ngIf="dialogType === 13">
  <nb-card-header>Additional Info</nb-card-header>
  <nb-card-body style="text-align:left" *ngIf="pfspoText">
    <label>Planned quantity:</label>
    <ngx-doc-form-texfield class="fullwidth" mode="integer" [(value)]="pfspoText.quantity"></ngx-doc-form-texfield>
    <br>
    <br>
    <br>

    <label>Text:</label>
    <ngx-doc-form-texfield class="fullwidth" [(value)]="pfspoText.text"></ngx-doc-form-texfield>
    <br>
  </nb-card-body>
  <nb-card-footer>
    <button class="btn btn-success btn-sm"  (click)="responseText=$any(pfspoText);ok()">Ok</button>
    <button class="btn btn-danger btn-sm"  (click)="cancel()">Cancel</button>
  </nb-card-footer>
</nb-card>

<!--==================================================================================================================================================-->
<!-- checklist -->

<nb-card *ngIf="dialogType === 14">
  <nb-card-header>CHECKLIST</nb-card-header>
  <nb-card-body>
    <table style="margin: 0 auto">
      <ng-container *ngFor="let item of checklistItems; index as idx">
        <tr style="cursor:pointer" (click)="item.checked = !item.checked">
          <td style="padding: 10px 0;text-align:center"><input type="checkbox" [ngModel]="item.checked" (click)="item.checked = !item.checked;$event.stopPropagation()"/></td>
          <td style="text-align:left">{{item.text}}</td>
        </tr>
      </ng-container>
    </table>
  </nb-card-body>
  <nb-card-footer>
    <!-- <button class="btn btn-primary btn-sm" (click)="ok()" [attr.disabled]="!isChecklistChecked() ? true : null">{{ isChecklistChecked() ? 'Ok' : 'Skip' }}</button> -->
    <button class="btn btn-primary btn-sm" (click)="ok()">{{ isChecklistChecked() ? 'Ok' : 'Skip' }}</button>
  </nb-card-footer>
</nb-card>

<!--==================================================================================================================================================-->
<!-- changelog -->
<nb-card *ngIf="dialogType === dialog.changelog">
  <nb-card-header>Changelog</nb-card-header>
  <nb-card-body>
    <div>Entry:
    <table style="width: 100%">
      <ng-container *ngFor="let item of changelog.items; last as lastItem; first as firstItem; index as idx">
        <tr>
          <td style="color:gray;font-size:small">{{idx}}</td>
          <td><button *ngIf="lastItem" class="nrk-doc-button" (click)="addLogItem(changelog)">+</button></td>
          <td><button *ngIf="!firstItem" class="nrk-doc-button" (click)="removeLogItem(changelog, idx)">-</button></td>
          <td><ngx-doc-form-filter-select class="fullwidth" [(value)]="item.quickText" [options]="quickTextOptions" (valueChange)="changelog.dirty = true"></ngx-doc-form-filter-select></td>
        </tr>
        <tr>
          <td></td>
          <td style="width:23em" colspan="3"><ngx-form-text [multiline]="1" [(value)]="item.text" (valueChange)="changelog.dirty = true"></ngx-form-text></td>
        </tr>
      </ng-container>
    </table></div>
    <br><br>
    <ng-container *ngIf="keepOldIssueDate !== null">
      <div>
        <div style="display:inline-block"><ngx-doc-form-checkbox label="Keep old IssueDate" [(value)]="keepOldIssueDate"></ngx-doc-form-checkbox></div>
        <div style="display:inline-block;padding:0 0 2px 0.5rem">({{ compilation.issueDate | date : 'yyyy-MM-dd' }})</div>
      </div>
      <br>
    </ng-container>
    <div>Date: {{ changelog.date | date : 'yyyy-MM-dd' }}</div>
    <br>
    <div>User: {{ getUserShort($any(changelog).user) }}</div>
    <br>
    <div>Type: {{ getChangeType(changelog.type) }}</div>
    <br>
    <div *ngIf="compilation?.documentStatus">Docment #: {{ changelog.documentNumber }}</div>
    <br>
    <div *ngIf="compilation?.documentStatus">Change: {{ changelog.change }}</div>
  </nb-card-body>
  <nb-card-footer>
    <button class="btn btn-success btn-sm"  (click)="ok()">Ok</button>
  </nb-card-footer>
</nb-card>

<!--==================================================================================================================================================-->
<!-- newOffer -->

<nb-card *ngIf="dialogType === 17">
  <nb-card-header>New Offer{{compilation && compilation.type === typeData.get('TOF').id ? ' template' : ''}}</nb-card-header>
  <nb-card-body>

    <ng-container *ngIf="businessOptions">
      <label>Select recipient</label>
      <ngx-doc-form-filter-select class="fullwidth" [(value)]="response.businessId" [options]="businessOptions"></ngx-doc-form-filter-select>
      <ng-container *ngIf="!offerFromTemplate">
        <br>
        <br>
        <br>
        <br>
      </ng-container>
      <br>
      <label>Select manufacturer</label>
      <ngx-doc-form-filter-select class="fullwidth" [(value)]="response.supplierId" [options]="businessOptions"></ngx-doc-form-filter-select>
      <ng-container *ngIf="!offerFromTemplate">
        <br>
        <br>
        <br>
        <br>
      </ng-container>
      <br>
    </ng-container>

    <label>Select issuer</label>
    <ngx-doc-form-dropdown class="fullwidth" [(value)]="response.issuer" [options]="issuerOptions"></ngx-doc-form-dropdown>
    <ng-container *ngIf="!offerFromTemplate">
      <br>
      <br>
      <br>
      <br>
    </ng-container>
    <br>
    <br>

    <ng-container *ngIf="offerFromTemplate">
      <label>Delivery conditions import</label>
      <ul>
        <li><input type="radio" [value]="1" name="deliveryConditions" [(ngModel)]="deliveryConditions"/><span (click)="deliveryConditions=1">Inherit from recipient</span></li>
        <!-- <li><input type="radio" [value]="2" name="deliveryConditions" [(ngModel)]="deliveryConditions"/><span (click)="deliveryConditions=2">Inherit from parent document</span></li> -->
        <li><input type="radio" [value]="3" name="deliveryConditions" [(ngModel)]="deliveryConditions"/><span (click)="deliveryConditions=3">Leave empty</span></li>
      </ul>
      <br>

      <label>Terms of payment import</label>
      <ul>
        <li><input type="radio" [value]="1" name="termsOfPayment" [(ngModel)]="termsOfPayment"/><span (click)="termsOfPayment=1">Inherit from recipient</span></li>
        <!-- <li><input type="radio" [value]="2" name="termsOfPayment" [(ngModel)]="termsOfPayment"/><span (click)="termsOfPayment=2">Inherit from parent document</span></li> -->
        <li><input type="radio" [value]="3" name="termsOfPayment" [(ngModel)]="termsOfPayment"/><span (click)="termsOfPayment=3">Leave empty</span></li>
        <li><input type="radio" [value]="4" name="termsOfPayment" [(ngModel)]="termsOfPayment"/><span (click)="termsOfPayment=4">NO COMMERCIAL VALUE...</span></li>
      </ul>
      <br>

      <label>New project:</label>
      <ul>
        <li><input type="radio" [value]="true" name="newProject" [(ngModel)]="newProject.create"/><span (click)="newProject.create=true">Yes</span></li>
        <li><input type="radio" [value]="false" name="newProject" [(ngModel)]="newProject.create"/><span (click)="newProject.create=false">No</span></li>
      </ul>
      <ng-container *ngIf="newProject.create">
        <ul class="mt-3">
          <li>Enter new project number (Trial #)<ngx-doc-form-texfield class="fullwidth" [(value)]="newProject.name"></ngx-doc-form-texfield></li>
        </ul>
      </ng-container>
      <br>

    </ng-container>

  </nb-card-body>
  <nb-card-footer>
    <!-- <button class="btn btn-primary btn-sm" (click)="ok()" [attr.disabled]="!isChecklistChecked() ? true : null">{{ isChecklistChecked() ? 'Ok' : 'Skip' }}</button> -->
    <button class="btn btn-success btn-sm" (click)="
      deliveryConditions && response.deliveryConditions = deliveryConditions; 
      termsOfPayment && response.termsOfPayment = termsOfPayment;
      newProject && response.newProject = newProject;
      
      ok()">Ok</button>
  </nb-card-footer>
</nb-card>

<!--==================================================================================================================================================-->
<!-- manageTemplates -->

<nb-card *ngIf="dialogType === 18">
  <nb-card-header>TEMPLATES</nb-card-header>
  <nb-card-body>
    <h4>Add template Business</h4>
    Name:
    <ngx-doc-form-texfield [(value)]="templateBusinessName"></ngx-doc-form-texfield>
    <button class="nrk-button" (click)="addTemplateBusiness()">Add</button>
  </nb-card-body>
  <nb-card-footer>
    <button class="btn btn-primary btn-sm" (click)="ok()">Close</button>
  </nb-card-footer>
</nb-card>

<!--==================================================================================================================================================-->
<!-- bugReport -->

<nb-card *ngIf="dialogType === 19">
  <nb-card-header>Bugreport</nb-card-header>
  <nb-card-body>
    <div style="text-align:left">
      <div>Description / Teebug #</div>
      <ngx-form-text [multiline]="5" [(value)]="responseText"></ngx-form-text>
      <div style="cursor:pointer; margin-top:1em" (click)="toggleDebugData()">show debug data ▼</div>
      <textarea *ngIf="showDebugData" cols="40" rows="10">{{this.debugPayload}}</textarea>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button class="btn btn-primary btn-sm" (click)="ok()">Send</button>
    <button class="btn btn-primary btn-sm" (click)="cancel()">Close</button>
  </nb-card-footer>
</nb-card>

<!--==================================================================================================================================================-->
<!-- dueDateCalculation -->

<nb-card *ngIf="dialogType === dialog.dueDateCalculation">
  <nb-card-header>Due Date</nb-card-header>
  <nb-card-body>
    <!-- <h4>Due Date</h4> -->
    <br>
    Current due date: {{ (compilation.dueDate  | date : 'yyyy-MM-dd') || 'undefined' }} <br><br>
    <div style="text-align: left">
      Calculated due date:
      <ngx-doc-form-date [(value)]="$any(this).dueDate"></ngx-doc-form-date>
      <br>
      Due text:
      <ngx-form-text [multiline]="10" [(value)]="compilation.dueText"></ngx-form-text>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button class="btn btn-primary btn-sm" (click)="setNewDueDate(dueDate)">Set new Due Date</button>
    <button *ngIf="compilation.dueDate" class="btn btn-primary btn-sm" (click)="ok()">Keep {{ (compilation.dueDate  | date : 'yyyy-MM-dd') || 'undefined' }}</button>
  </nb-card-footer>
</nb-card>

<!--==================================================================================================================================================-->
<!-- upload window -->

<nb-card *ngIf="dialogType === dialog.upload">
  <nb-card-header>Upload File</nb-card-header>
  <nb-card-body>
    <h4>{{message}}</h4>
    <ngx-form-upload [fullwidth]="true" [hideComment]="false" [hideCheckbox]="false" accept="application/pdf" url="/api/v1/upload" [(files)]="files"></ngx-form-upload>
  </nb-card-body>
  <nb-card-footer>
    <button class="btn btn-primary btn-sm" (click)="ok()">Ok</button>
  </nb-card-footer>
</nb-card>

<!--==================================================================================================================================================-->
<!-- dateSelection -->

<nb-card *ngIf="dialogType === dialog.dateSelection">
  <nb-card-header>Date Selection</nb-card-header>
  <nb-card-body>
    <h4>{{message}}</h4>
    <div style="height:20em">
      <ngx-doc-form-date [(value)]="response.date" (valueChange)="response.date = $event; ok()" [styles]="{'justify-content':'center'}"></ngx-doc-form-date>
    </div>
  </nb-card-body>
</nb-card>

<!--==================================================================================================================================================-->
<!-- obic -->

<nb-card *ngIf="dialogType === dialog.obic">
  <nb-card-header>OBIC {{compilation.lineItems[0].tbCode}}</nb-card-header>
  <nb-card-body>
    <div style="text-align:left">
      <div class="mb-1">Quantity</div>
      <ngx-form-text [multiline]="1" [(value)]="response.quantity"></ngx-form-text>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button class="btn btn-primary btn-sm" (click)="ok()">Ok</button>
  </nb-card-footer>
</nb-card>

<!--==================================================================================================================================================-->
<!-- mailTemplate -->

<nb-card *ngIf="dialogType === dialog.mailTemplate">
  <nb-card-header>{{mailInfo.in | documentNumber}} / {{mailInfo.dn | documentNumber}}</nb-card-header>
  <nb-card-body>
    <div class="mail-template" style="text-align:left">
      <div class="send-to">{{mailInfo.invoiceSendTo}}</div>
      <div class="send-to">{{mailInfo.deliveryNoteSendTo}}</div>
      <div class="send-to">{{mailInfo.testCertificateSendTo}}</div>

      <div>
        <button class="nrk-button" (click)="sendInMail()">send IN Mail</button>
        <button class="nrk-button" (click)="sendDnMail()">send DN Mail</button>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button class="btn btn-primary btn-sm" (click)="ok()">Ok</button>
  </nb-card-footer>
</nb-card>

<!--==================================================================================================================================================-->
<!-- paymentDate -->

<nb-card *ngIf="dialogType === dialog.paymentDate">
  <nb-card-header>Payment Date</nb-card-header>
  <nb-card-body>
    <div style="text-align:center;height:21rem;display:inline-block">
      Enter Payment Date:
      <ngx-doc-form-date [(value)]="$any(compilation).paymentDate"></ngx-doc-form-date>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button class="btn btn-primary btn-sm" (click)="ok()">Ok</button>
  </nb-card-footer>
</nb-card>

