import { Pipe, PipeTransform } from '@angular/core';
import { nrkPad } from '../../@core/common/global';

@Pipe({ name: 'nrkPad' })
export class NrkPadPipe implements PipeTransform {

  transform(input: number | string, pad: number, fill: string = '0'): string {
    return nrkPad(input, pad, fill)
  }
}
