import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import {
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
  NbCheckboxModule,
  NbAccordionModule,
  NbProgressBarModule,
  NbIconModule,
} from '@nebular/theme';

import {
  HeaderComponent,
  SearchInputComponent,
  ThemeSettingsComponent,
  // TinyMCEComponent,
} from './components';
import { CapitalizePipe, PluralPipe, RoundPipe, TimingPipe } from './pipes';
import {
  SampleLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { NIPPONRIKA_THEME } from './styles/theme.nipponrika';
import {EnumKeysPipe} from './pipes';
import { StatusWindowComponent } from './components/statusWindow.component';
import { FreeDraggingDirective } from './directives/free-dragging.directive';
import { TypeofPipe } from './pipes/typeof.pipe';
import { DocumentNumberPipe } from './pipes/documentNumber.pipe';
import { StringToNumberPipe } from './pipes/string-to-number.pipe';
import { RouterModule } from '@angular/router';
import { PriceUnitPipe } from './pipes/priceUnit.pipe';
import { NrkCurrencyPipe } from './pipes/nrkCurrency.pipe';
import { NrkSanitizePipe } from './pipes/sanitize.pipe';
import { NrkPadPipe } from './pipes/pad.pipe';
import { NrkKeyValuePipe } from './pipes/nrkKeyValue.pipe';


const BASE_MODULES = [CommonModule, RouterModule];

const NB_MODULES = [
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NgbModule,
  NbAccordionModule,
  NbProgressBarModule,
  NbIconModule,
];

const COMPONENTS = [
  HeaderComponent,
  SearchInputComponent,
  ThemeSettingsComponent,
  // TinyMCEComponent,
  SampleLayoutComponent,
  StatusWindowComponent,
];

const DIRECTIVES = [
  FreeDraggingDirective,
]

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  EnumKeysPipe,
  TypeofPipe,
  DocumentNumberPipe,
  StringToNumberPipe,
  PriceUnitPipe,
  NrkCurrencyPipe,
  NrkSanitizePipe,
  NrkPadPipe,
  NrkKeyValuePipe,
];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: 'nipponrika',
    },
    [ DEFAULT_THEME,  NIPPONRIKA_THEME],
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
];

@NgModule({
  imports: [...BASE_MODULES, ReactiveFormsModule.withConfig({callSetDisabledState: 'whenDisabledForLegacyCode'}), ...NB_MODULES],
  exports: [...BASE_MODULES, ReactiveFormsModule, ...NB_MODULES, ...COMPONENTS, ...PIPES, ...DIRECTIVES],
  declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS, RoundPipe],
    };
  }
}
