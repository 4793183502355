import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { AuthGuardService } from './@core/service/auth-guard.service';
import { NbAuthPatchComponent } from './auth/NbAuthPatch.component';
import { NbLogoutPatchComponent } from './auth/NbLogoutPatch.component';
import { NbLoginPatchComponent } from './auth/login/login-patch.component';

const routes: Routes = [
  { path: 'pages',
    loadChildren: () => import('app/pages/pages.module').then(m => m.PagesModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'auth',
    component: NbAuthPatchComponent,
    children: [
      {
        path: '',
        component: NbLoginPatchComponent,
      },
      {
        path: 'login',
        component: NbLoginPatchComponent,
      },
      {
        path: 'register',
        component: NbRegisterComponent,
      },
      {
        path: 'logout',
        component: NbLogoutPatchComponent,
      },
      {
        path: 'request-password',
        component: NbRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NbResetPasswordComponent,
      },
    ],
  },
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth/login' },
];

const config: ExtraOptions = {
    useHash: true
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
