

import {NbAuthJWTToken, NbPasswordAuthStrategy} from '@nebular/auth/'

export const AUTH_CONFIG = {
  strategies: [
    NbPasswordAuthStrategy.setup({
      baseEndpoint: '/api/v1/auth',
      name: 'email',
      login: {
        endpoint: '/login',
      },
      logout: {
        endpoint: '/logout',
        method: 'POST',
      },
      requestPass: {
        endpoint: '/request-pass',
      },
      resetPass: {
        endpoint: '/reset-pass',
      },
      token: {
        class: NbAuthJWTToken,
        key: 'token',
      },
      errors: {
        key: 'data.errors',
      },
      messages: {
        key: 'data.messages',
      },
    }),
  ],
  forms: {},
};
