import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import { ApiService } from './api.service';
import { AuthGuardService } from './auth-guard.service';
import { ErpRoles, UserModel } from '../../@models';

@Injectable()
export class RoleGuardService implements CanActivate {

  constructor(private api: ApiService, private auth: AuthGuardService, private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return await this.check(route.data);
  }

  async check(data): Promise<boolean> {
    return new Promise<boolean>(res => {
      let allowed = false;
      if (this.auth.user) {
        allowed = RoleGuardService.checkRights(data, this.auth.user);
        if (!allowed) 
          this.router.navigate(['/pages/403'])
        res(allowed);
    } else {
        this.auth.loadMe().then(usr => {
          allowed = RoleGuardService.checkRights(data, usr);
          if (!allowed) 
            this.router.navigate(['/pages/403'])
          res(allowed);
        })
      }
    })
  }

  static checkRights(data, user: UserModel) {

    // const callingFunctionName = (new Error()).stack.split('\n')[4].trim().split(' ')[1]; // Get the name of the calling function
    // if (callingFunctionName === 'walkMenu' && data.erpRoles?.includes('warehousing')) {
    //   console.log('check Rights --->', data, user);
    // }

    if (!data?.erpRoles && !data?.antiRoles) return true;

    for (const erpRole in user.erpRoles) {
      // console.log('checkRights', erpRole, user.erpRoles[erpRole], data.antiRoles?.includes(erpRole), data, user, data.antiRoles, data.erpRoles)
      if ((user.admin && data.erpRoles?.includes('super')) || (user.erpRoles[erpRole] && data.erpRoles?.includes(erpRole))) return true;
    }

    // if (!data?.erpRoles && !Object.keys(user.erpRoles).find(f => data.antiRoles.includes(f))) return true;

    if (!data?.erpRoles && !Object.entries(user.erpRoles).find(([key, value]) => data.antiRoles.includes(key) && value === true)) return true;

    // if (navigate) this.router.navigate(['/pages/403'])
    return false;
  }


}
