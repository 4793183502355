import { Pipe, PipeTransform } from '@angular/core';
import { UnitData } from '../../@models/erp/compilationData';

@Pipe({ name: 'priceUnit' })
export class PriceUnitPipe implements PipeTransform {

  transform(input: number, short = true): string {
    // console.log('input', input, short, UnitData.get(+input).short);
    return short ? UnitData.get(+input).short : UnitData.get(+input).name;
  }
}
