/* eslint-disable no-shadow */
import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {NbDialogRef, NbToastrService} from '@nebular/theme';
import { getDocumentNumber, mongoId } from '../../../../@core/common/global';
import { ApiService } from '../../../../@core/service/api.service';
import { AuthGuardService } from '../../../../@core/service/auth-guard.service';
import { SharedDataService } from '../../../../@core/service/shared-data.service';
import { TreeService } from '../../../../@core/service/tree.service';
import { BusinessField, BusinessModel, ContactModel, EBusinessType, EContactFieldType, EContactType, FileModel, UserModel } from '../../../../@models';
import { DownloadType } from '../../../../@models/erp/baseProduct';
import { CompilationModel, EPackingStatus } from '../../../../@models/erp/compilation';
import { CompilationTypeData, IssuerData, SpoStatusData } from '../../../../@models/erp/compilationData';
import { AssociatedPfspo, LineItemModel } from '../../../../@models/erp/lineItem';
import { Changelog, ChangelogEntry } from '../changelog/changelog.component';

export enum NrkDialogType {
  plain = 1,
  okCancelResponse = 2,
  revise = 3,
  spoSelection = 4,
  pdfSelection = 5,
  metrics = 6,
  okCancel = 7,
  nrkaOrSelect = 8,
  pfcsponrkg = 9,
  updateDeliveryAndTerms = 10,
  cloneBaseProduct = 11,
  pfspoSelector = 12,
  pfspoAvailability = 23,
  pfspoText = 13,
  checklist = 14,
  input = 15,
  changelog = 16,
  newOffer = 17,
  manageTemplates = 18,
  bugReport = 19,
  dismiss = 20,
  updateDelivery = 21,
  dueDateCalculation = 22,
  upload = 24,
  dateSelection = 25,
  obic = 26,
  mailTemplate = 27,
  paymentDate = 28,
}

export enum NrkDialogButton {
  save = 1,
  dismiss,
  cancel,
  ok,
  savePdf,
  mergePdf,
  update,
}

export enum SpoChoice {
  fromRecipinet = 1,
  fromParent,
  empty,
  noCommervialValue,
}

export enum InheritPriceOption {
  update = 1,
  keep,
  zero,
}

@Component({
  selector: 'ngx-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss'],
})
export class CommonDialogComponent implements OnInit, AfterViewInit {

  @Input() message: string;
  @Input() dialogType: NrkDialogType = NrkDialogType.plain;
  @Input() okText: string = 'Ok';
  @Input() cancelText: string = 'Cancel';
  @Input() orderOptions: any = [];
  @Input() businessOptions: any = [];
  @Input() responseText: string = '';
  @Input() header: string;
  @Input() vatOptions: any = [];
  @Input() attachOnly: boolean = false;

  @Input() compilation: CompilationModel;
  @Input() lineItem: LineItemModel;
  @Input() position: number;
  @Input() pfspo: AssociatedPfspo;
  @Input() disabled: boolean;
  @Input() recalc: Function;

  @Input() user: UserModel;

  @Input() offerFromTemplate = false;
  @Input() customers: BusinessField[] = [];
  @Input() suppliers: BusinessField[] = [];

  @Input() deliveryConditions: SpoChoice = SpoChoice.fromRecipinet;
  @Input() termsOfPayment: SpoChoice = SpoChoice.fromRecipinet;
  @Input() inheritPrice = InheritPriceOption.zero;

  @Input() showDeliveryConditions = true;

  @Input() businesses: BusinessModel[];

  @Input() contact: ContactModel;

  @Input() relatedDocuments: {doc: CompilationModel, text: string}[];
  @Input() removedSpos: string[];

  @Input() checklistItems = null;

  @Input() changelog: ChangelogEntry;
  quickTextOptions: any;
  // @Input() quickTextOptions;
  // @Input() addLogItem: (changelog: any) => any;
  // @Input() removeLogItem: (changelog: any, idx: number) => any;
  // @Input() getUserShort: (user: UserModel) => any;
  // @Input() getChangeType: (changeType: ChangelogEntryType) => any;

  @Input() debugPayload: string;

  @Input() dueDate: Date;

  @Input() files: FileModel[];

  @Input() mailInfo: any;

  @ViewChild('okBtn') okBtn: ElementRef;

  typeData = CompilationTypeData;
  issuerData = IssuerData;
  dialog = NrkDialogType;

  vat = 0;
  orderCode = null;

  issuerOptions = IssuerData.getOptions();

  downloadType = DownloadType;

  updateDelivery = true;
  updateTerms = true;

  parentOc: CompilationModel = null;
  static parentOcBackup: CompilationModel = null;

  associatedSpos: CompilationModel[] = [];
  static associatedSposBackup: CompilationModel[] = [];

  cancelledDownstreamDocuments = [];

  attachToOC = false;

  newProject: { create: boolean, name: string } = { create: false, name: null };

  keepOldIssueDate = null;

  priceOptions = [
    { id: InheritPriceOption.update, name: 'update'},
    { id: InheritPriceOption.keep, name: 'keep'},
    { id: InheritPriceOption.zero, name: 'zero'},
  ]

  pfspoText = { quantity: '', text: '' };

  response: any = {};

  templateBusinessName = '';

  showDebugData = false;

  // setSposCompleted = false;

  deliveryAndTerms: any = {};

  // customers: BusinessModel[] = null
  // suppliers: BusinessModel[] = null

  constructor(
    private cdRef: ChangeDetectorRef,
    protected ref: NbDialogRef<CommonDialogComponent>,
    public api: ApiService,
    protected tree: TreeService,
    public data: SharedDataService,
    private toast: NbToastrService,
    public auth: AuthGuardService,
  ) { }

  ngAfterViewInit(): void {
    this.okBtn?.nativeElement.focus();
  }

  async ngOnInit() {

    if (this.relatedDocuments) {
      if (this.compilation) {
        const curr = this.relatedDocuments.find(f => f.doc.code === this.compilation.code);
        this.relatedDocuments = this.relatedDocuments.filter((_f, idx) => idx >= this.relatedDocuments.indexOf(curr));
      }
      this.cancelledDownstreamDocuments = this.relatedDocuments.map(() => true);
    }
    if (!this.compilation) return;

    this.vat = this.vatOptions && this.vatOptions.lenght && this.vatOptions[0].id;
    if (this.compilation.type && ['IN', 'PFIN', 'CN', 'PFCN'].includes(CompilationTypeData.get(this.compilation.type).code)) {
      if (this.tree.linkedOC) {
        this.parentOc = this.tree.linkedOC;
        this.associatedSpos = this.tree.linkedPfspos;
        CommonDialogComponent.parentOcBackup = this.tree.linkedOC;
        CommonDialogComponent.associatedSposBackup = this.tree.linkedPfspos;
        // console.log('associated spos', this.associatedSpos);
        // this.associatedSpos = JSON.parse(JSON.stringify(this.tree.linkedPfspos)); // avoid overwriting tree on doc save
      } else {
        this.parentOc = CommonDialogComponent.parentOcBackup;
        this.associatedSpos = CommonDialogComponent.associatedSposBackup;
        // console.log('no linked oc, associated spos', this.associatedSpos);
      }
    }

    if (this.changelog) {
      if (!this.changelog.items || !this.changelog.items.length) this.changelog.items = [{ quickText: '', text: '' }];
      const quickTextData = await this.api.getCompilationData('changelog') as any; // TODO: type: CompilationDataModel[]
      let itemQt: any;
      if (this.compilation.documentStatus) { // CompilationModel
        itemQt = quickTextData.find(f => f.id === CompilationTypeData.get(this.compilation.type).code) || { data: []};
        const generalQt = quickTextData.find(f => f.id === 'GN') || { data: [] };
        this.quickTextOptions = [
          ...generalQt.data.map(m => ({ id: m, name: m})),
          ...itemQt.data.map(m => ({ id: m, name: m})),
        ];
      } else if ((this.compilation as any).product) { // FinishedProductModel
        itemQt = quickTextData.find(f => f.id === 'FP');
        this.quickTextOptions = itemQt.data.map(m => ({ id: m, name: m}));
      } else { // BaseProductModel
        itemQt = quickTextData.find(f => f.id === 'BP');
        this.quickTextOptions = itemQt.data.map(m => ({ id: m, name: m}));
      }
    }

    if (this.dialogType === NrkDialogType.updateDeliveryAndTerms) {
      this.deliveryAndTerms = this.data.getDeliveryAndTerms(this.compilation.recipient);
    } else if (this.dialogType === NrkDialogType.updateDelivery) {
      this.deliveryAndTerms = this.data.getDeliveryAndTerms(this.compilation.deliveryRecipient, this.contact);
    } else if ( this.dialogType === NrkDialogType.obic) {
      this.response.quantity = this.compilation.lineItems[0].quantity;
    }

    this.response.files = this.files;

  }

  save() {
    if (this.dialogType === NrkDialogType.plain) {
      this.ref.close({ id: this.dialogType, button: NrkDialogButton.save });
    } else  {
      const ret = { id: this.dialogType, button: NrkDialogButton.save, text: this.responseText };
      if (this.relatedDocuments) {
        ret['cancelDocs'] = this.relatedDocuments
          .filter((_d, idx) => this.cancelledDownstreamDocuments[idx])
          .map(m => m.doc);
      }
      this.ref.close(ret);
    }
  }

  dismiss() {
    this.ref.close({ id: this.dialogType, button: NrkDialogButton.dismiss });
  }

  cancel() {
    this.ref.close({ id: this.dialogType, button: NrkDialogButton.cancel });
  }

  async ok() {
    if (this.dialogType === NrkDialogType.bugReport) {
      this.ref.close({ description: this.responseText });
      return;
    }

    if (this.dialogType === NrkDialogType.newOffer) {
      let valid = true;
      if (!this.response.issuer) {
        this.toast.danger('Issuer is required', 'No issuer');
        valid = false;
      }
      if (this.businessOptions && !this.response.businessId) {
        this.toast.danger('Recipient is required', 'No recipient');
        valid = false;
        return;
      }
      if (this.response.newProject.create && !this.response.newProject.name) {
        this.toast.danger('Project number / Trial number is required', 'No project #');
        valid = false;
        return;
      }
      await this.data.loadProducts();
      if (this.data.baseProducts.find(bp => bp.trial === this.response.newProject.name)) {
        this.toast.danger('Project / Trial number already exists', 'Project # error')
        valid = false;
        return;
      }
      if (!valid) return;
    }

    if (this.dialogType === NrkDialogType.spoSelection
      || this.dialogType === NrkDialogType.nrkaOrSelect
      || this.dialogType === NrkDialogType.pfcsponrkg ) {

      this.ref.close({ id: this.dialogType, button: NrkDialogButton.ok, businessId: this.responseText,
        deliveryConditions: this.deliveryConditions, termsOfPayment: this.termsOfPayment, vat: this.vat,
        order: this.orderCode, price: this.inheritPrice, attachToOC: this.attachToOC });
    } else if (this.dialogType === NrkDialogType.changelog) {
      if (!this.keepOldIssueDate) this.compilation.issueDate = new Date();
      this.ref.close({ id: this.dialogType, button: NrkDialogButton.ok, changelog: this.changelog });
    } else {
      this.ref.close({ id: this.dialogType, button: NrkDialogButton.ok,
        response: this.response && Object.keys(this.response).length ? this.response : this.responseText,
      });
    }
  }

  update() {
    this.ref.close({
      id: this.dialogType,
      button: NrkDialogButton.update,
      updateDelivery: this.updateDelivery,
      updateTermsOfPayment: this.updateTerms,
    });
  }

  pdf(type: DownloadType, dn = false, tc = false) {
    this.ref.close({ id: this.dialogType, downloadType: type, dn, tc });
  }

  getOcTitle() {
    return this.parentOc && `${this.parentOc.nameOverride || (this.parentOc.code + 'VS' + this.parentOc.version)}`;
  }

  async setParentOcCompleted() {
    this.parentOc = await this.api.loadCompilationByCode(this.parentOc.code);
    await Changelog.addAutolog(this.parentOc, this.user, this.api, [{
      quickText: 'Set completely invoiced',
      text: `To yes, triggered by ${this.user.short} from ${getDocumentNumber(this.compilation)}`,
    }]);
    this.parentOc.completelyInvoiced = true;
    if (this.parentOc.packingStatus === EPackingStatus.delivered) this.parentOc.packingStatus = EPackingStatus.completed;
    this.api.saveCompilation(this.parentOc);
    this.tree.synchronizeSharedFields(this.parentOc);
  }

  async setSpoCompleted(spo: CompilationModel) {
    // console.log('setSpoCompleted doc.code', spo.code);
    const doc = await this.api.loadCompilationByCode(spo.code);
    await Changelog.addAutolog(spo, this.user, this.api, [{
      quickText: 'Set associated (PF)SPOs completed',
      text: `To yes, triggered by ${this.user.short} from ${getDocumentNumber(this.compilation)}`,
    }]);
    doc.transportRun.spoStatus = SpoStatusData.get('completed').id;
    this.api.saveCompilation(doc);
  }

  setAssociatedSposCompleted() {
    this.associatedSpos.forEach(el => this.setSpoCompleted(el));
  }

  clone() {
    this.ref.close({
      id: this.dialogType,
      customers: this.customers,
      suppliers: this.suppliers,
    })
  }

  // checklistChanged(item: any, checked: boolean) {
  //   console.log('pre',item, checked);
  //   console.log('chitems', this.checklistItems.map(m => m.checked));
  //   item.checked = checked;
  //   console.log('post',item, checked);
  //   // console.log('checked', ev, ev.target, ev.target.value, ev.target.checked);
  //   console.log('chitems', this.checklistItems.map(m => m.checked));
  //   this.isChecklistChecked = !this.checklistItems.find(f => !f.checked);
  //   console.log(this.isChecklistChecked);
  // }

  isChecklistChecked() {
    return !this.checklistItems.find(f => !f.checked);
  }
  // checkedChange(ev) {
  //   console.log('checked', ev)
  // }

  updateIssueDate() {
    this.compilation.issueDate = new Date();
    this.api.saveCompilation(this.compilation);
  }

  getUserShort(userId: string) {
    const user = this.data.users?.find(u => u._id === mongoId(userId));
    return user ? user.short : '';
  }

  getChangeType(changeType: number) {
    return Changelog.typeToString(changeType);
  }

  addLogItem(logEntry: any) {
    logEntry.items.push({ quickText: '', text: '' });
  }

  removeLogItem(logEntry: any, idx: number) {
    logEntry.items.splice(idx, 1);
  }

  addTemplateBusiness() {
    if (this.businesses.find(b => b.name === this.templateBusinessName.trim())) {
      this.toast.danger('Template business name already exists', 'Duplicate names');
    } else {
      const newTemplate = new BusinessModel();
      newTemplate.type = EBusinessType.Template;
      newTemplate.name = this.templateBusinessName;
      newTemplate.manager = this.auth.user.contact;
      newTemplate.contacts = [];
      newTemplate.projects = [];
      this.templateBusinessName = '';
      // console.log('new template business', newTemplate);
      const subscr = this.api.saveBusiness(newTemplate).subscribe(() => {
        this.toast.success('template business successfully created', 'business saved');
        this.data.reloadBusinesses();
        subscr.unsubscribe();
      });
    }
  }

  toggleDebugData() {
    this.showDebugData = !this.showDebugData;
  }

  // evalState() {
    // update spo status
    // console.log('spo status', this.setSposCompleted);
    // if (this.setSposCompleted) {
    //   this.setAssociatedSposCompleted();
    // }
  // }

  setNewDueDate(date: Date) {
    this.compilation.dueDate = date;
    this.ref.close();
  }

  sendEmail(to: string, subject: string, message: string) {
    const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
    window.location.href = mailtoLink;
  }

  dateFormat(date: Date) {
    return date.toISOString().replace(/T.*/, '');
  }

  sendInMail() {
    const text = `
      Dear business partner,

      Please find in attachment our invoice as follows:
      
      INNRKA230305 - Issue date 231117 - Cust reference 45387931 - Date 231117 - Total net 51480.58 EUR
      Due date 231128 
      
      Position details:
      
      INNRKA230305VS1
      Dispatch date NRKA: 2023-08-30 - Expected ETA date: 2023-08-31
      Pos 1 - TSA AT Wr. Neudorf - 45387931 - POPos 1 - PD 1/1 - EM-022-000010506 - RikaCalfib G468N 00120; 0020x0050-A-55-20-R - 8064 pcs
      
      INNRKA230305VS1
      Dispatch date NRKA: 2023-08-30 - Expected ETA date: 2023-08-31
      Pos 2 - ... 
      
      We kindly request for a timely settlement and remain with best regards
      
      ${this.user.name}
    `
    this.sendEmail(this.user.username, this.mailInfo.in, text);
  }

  sendDnMail() {
    const dn: CompilationModel = this.mailInfo.dn;
    const business = this.data.businesses.find(f => mongoId(f) === mongoId(dn.recipient))
    const text = `
      ${dn.code}
      Dispatch: ${this.dateFormat(dn.vieDispatchDate)} - ETA: ${this.dateFormat(dn.etaCustomerDate)} - ${business.name} - ${dn.customerDocumentNumber}
      POPos 1 - PD 1/1 - EM-022-000010506 - RikaCalfib G468N 00120; 0020x0050-A-55-20-R - TSA006113P0020 - 8064 pcs  
    `
    this.sendEmail(this.user.username, this.mailInfo.dn, text);
  }

}
